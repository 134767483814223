import React, {useEffect} from 'react';
import Home from "./Home";
import {Fab, Grid} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import MainCard from "../components/MainCard";
import {InputController, SelectController} from "../atoms/AtomsForms";
import {getSpecialtiesApi} from "../api/specialties/getSpecialties";
import {saveDoctorApi} from "../api/doctors/saveDoctorApi";
import {useNavigate} from "react-router-dom";


const CreateDoctor = () => {
    const [doctorData, setDoctorData] = React.useState({
        name: "",
        lastname: "",
        secondlastname: "",
        email: "",
        mobile: "",
        agreement: 84,
        specialty_id: ""
    });
    const [specialties, setSpecialties] = React.useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSpecialties = async () => {
            const specialtiesData = await getSpecialtiesApi();
            if (!specialtiesData.error) {
                const formattedSpecialties = specialtiesData.specialities.map(specialty => ({
                    id: specialty.id,
                    label: specialty.name
                }));
                setSpecialties(formattedSpecialties);
            } else {
                alert(specialtiesData.message)
            }
        };
        fetchSpecialties();
    }, []);

    const saveDoctor = async () => {
        const doctor = await saveDoctorApi(doctorData);
        console.log('doctor', doctor);
        if (!doctor.error) {
            console.log('responseDoctor', doctor);
            alert("Se ha creado el doctor correctamente");
            const id = doctor.doctor.id;
            window.location.href =`/doctor?id=${id}`;
            navigate(`/doctor?id=${id}`, {replace: true});
        } else {
            alert(doctor.message)
        }
    }

    return (
        <div>
            <Home children={
                <div style={{width: '100%'}}>
                    <Fab color="primary" aria-label="add" style={{
                        margin: 0,
                        top: 'auto',
                        right: 20,
                        bottom: 20,
                        left: 'auto',
                        position: 'fixed',
                    }}>
                        <IconButton aria-label="save" style={{color: "white"}} onClick={async () => {
                            await saveDoctor();
                        }
                        }>
                            <SaveIcon/>
                        </IconButton>
                    </Fab>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MainCard title="Datos del Doctor"
                                      content={'Fecha de registro: ' + doctorData?.created_at}>
                                <Grid container spacing={2} alignItems="center">
                                    <InputController
                                        label="Nombre"
                                        value={doctorData?.name}
                                        onChange={(e) => setDoctorData({
                                            ...doctorData,
                                            name: e.target.value
                                        })}
                                    />
                                    <InputController
                                        label="Apellido Paterno"
                                        value={doctorData?.lastname}
                                        onChange={(e) => setDoctorData({
                                            ...doctorData,
                                            lastname: e.target.value
                                        })}
                                    />
                                    <InputController
                                        label="Apellido Materno"
                                        value={doctorData?.secondlastname}
                                        onChange={(e) => setDoctorData({
                                            ...doctorData,
                                            secondlastname: e.target.value
                                        })}
                                    />
                                    <InputController
                                        label="Correo electrónico"
                                        value={doctorData?.email}
                                        onChange={(e) => setDoctorData({
                                            ...doctorData,
                                            email: e.target.value
                                        })}
                                    />
                                    <SelectController
                                        label="Especialidad"
                                        options={specialties}
                                        value={specialties.find(specialty => specialty.label === doctorData?.specialty_name) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setDoctorData({
                                                    ...doctorData,
                                                    specialty_id: newValue.id,
                                                    specialty_name: newValue.label
                                                });
                                            }
                                        }}
                                    />
                                    <InputController
                                        label="Teléfono móvil"
                                        value={doctorData?.mobile}
                                        onChange={(e) => setDoctorData({
                                            ...doctorData,
                                            mobile: e.target.value
                                        })}
                                    />
                                </Grid>
                            </MainCard>
                        </Grid>
                    </Grid>
                </div>
            } title={'Registro de doctor'}/>
        </div>
    );
}


export default CreateDoctor;
