import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination, IconButton, Box, Modal, TextField, Button
} from '@mui/material';
import {
  FirstPage, LastPage, NavigateBefore, NavigateNext, Edit as EditIcon, Delete as DeleteIcon
} from '@mui/icons-material';
import { createEntityContactApi } from '../../api/entities/storeContactEntity';

const ContactsTable = ({ headers, rows, rowsPerPageOptions, entityId }) => {
  console.log('rows', rows, entityId);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [showModal, setShowModal] = useState(false);
  const [entity, setEntity] = useState(null);
  const [modalMode, setModalMode] = useState(null);  // 'create' o 'edit'


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (row) => {
    console.log('row', row);
    setEntity(row);
    setShowModal(true);
  };

  const handleDelete = (row) => {
    // Lógica para eliminar el contacto
  };

  const editContact = () => {
    console.log('entity');
    // Lógica para editar el contacto
    setShowModal(false);
  };

  const handleCreate = () => {
    setEntity({});  // Inicializa una entidad vacía
    setModalMode('create');  // Establece el modo del modal a 'create'
    setShowModal(true);  // Muestra el modal
  };

  const createContact = async () => {
    console.log('entity', entity, entityId);
    // Lógica para crear el nuevo contacto
    await createEntityContactApi(entity, entityId);
    alert('Contacto creado');
    window.location.reload();
    setShowModal(false);
  };

  return (
    <>
      <Box style={{ paddingBottom: 70 }}>
        <Button variant="contained" color="primary" onClick={handleCreate} style={{ marginBottom: '20px' }}>
          Agregar Contacto
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) =>
                  header !== 'id' ? (
                    <TableCell key={index}>
                      {header}
                    </TableCell>
                  ) : null
                )}
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(row).map((key, cellIndex) => {
                    if (key !== 'id') {
                      return (
                        <TableCell key={cellIndex}>
                          {row[key]}
                        </TableCell>
                      );
                    }
                    return null;
                  })}
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <div style={{ padding: '20px', backgroundColor: '#fff', margin: 'auto', marginTop: '10%', width: '50%' }}>
          <h2>{modalMode === 'edit' ? 'Editar Contacto' : 'Agregar Contacto'}</h2>
          {headers.map((header) => {
            const headerLowerCase = header.toLowerCase();
            if (header !== 'id' && header !== 'Acciones' && header !== 'Estado') {
              return (
                <TextField
                  key={header}
                  label={header}
                  value={entity ? entity[headerLowerCase] : ''}
                  onChange={(e) => {
                    const updatedEntity = { ...entity, [headerLowerCase]: e.target.value };
                    setEntity(updatedEntity);
                  }}
                  margin="normal"
                  fullWidth
                />
              );
            }
            return null;
          })}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button variant="contained" color="primary" style={{ marginRight: '10px' }} onClick={() => modalMode === 'edit' ? editContact() : createContact()}>
              Guardar
            </Button>
            <Button variant="contained" style={{ backgroundColor: 'red', color: 'white' }} onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );

};

export default ContactsTable;
