import React, { useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Box,
} from "@mui/material";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const DynamicTablePatients = ({
  headers,
  rows,
  rowsPerPageOptions,
  pagination,
  onPageChange,
  amounts,
  onRowsPerPageChange,
  rowsPerPage,
  type,
  page,
}) => {
  const [checkedRows, setCheckedRows] = useState([]);
  const transformedRows = rows.map((row) =>
    row.map((cell, cellIndex) =>
      headers[cellIndex + 1] === "REP" ? (cell ? "SI" : "NO") : cell
    )
  );
  const displayedRows = transformedRows.map((row) =>
    row.filter((_, index) => headers[index] !== "id")
  );
  const navigate = useNavigate();
  const {
    first_page_url,
    last_page_url,
    next_page_url,
    per_page,
    total,
    prev_page_url,
  } = pagination;

  const tableRef = useRef(null);

  const handleRowClick = (row, rowIndex) => {
    navigate(`/patient?id=${rows[rowIndex][0]}`);
  };

  const totalPages = Math.ceil(total / per_page);

  return (
    <Box style={{ paddingBottom: 50 }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "10px",
        }}
      ></Box>
      <TableContainer component={Paper}>
        <Table ref={tableRef}>
          <TableHead
            sx={{
              backgroundColor: config.styles.principalColor,
            }}
          >
            <TableRow>
              {headers.map((header, index) =>
                header !== "id" ? (
                  <TableCell
                    key={index}
                    style={{ textAlign: "center", color: "black" }}
                  >
                    {header}
                  </TableCell>
                ) : null
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={() => handleRowClick(row, rowIndex)}
              >
                {row.map((cell, cellIndex) => {
                  return (
                    <TableCell style={{ textAlign: "center" }} key={cellIndex}>
                      {cell}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Registros por página",
                  },
                  native: true,
                }}
                ActionsComponent={({
                  count,
                  page,
                  rowsPerPage,
                  onPageChange,
                }) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <IconButton
                      onClick={(event) => onPageChange(event, 0, rowsPerPage)}
                      disabled={page === 0 || !first_page_url}
                    >
                      <FirstPage />
                    </IconButton>
                    <IconButton
                      onClick={(event) =>
                        onPageChange(event, page - 1, rowsPerPage)
                      }
                      disabled={page === 0 || !prev_page_url}
                    >
                      <NavigateBefore />
                    </IconButton>
                    <IconButton
                      onClick={(event) =>
                        onPageChange(event, page + 1, rowsPerPage)
                      }
                      disabled={page >= totalPages - 1 || !next_page_url}
                    >
                      <NavigateNext />
                    </IconButton>
                    <IconButton
                      onClick={(event) =>
                        onPageChange(event, totalPages - 1, rowsPerPage)
                      }
                      disabled={page >= totalPages - 1 || !last_page_url}
                    >
                      <LastPage />
                    </IconButton>
                  </div>
                )}
                labelRowsPerPage="Pacientes por página:"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DynamicTablePatients;
