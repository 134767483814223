import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PasswordsInsuranceTable from "../components/tables/PasswordsInsuranceTable";
import { createDataApi } from "../api/passwordInsurances/createData";
import { useSelector } from "react-redux";
import { SelectController } from "./AtomsForms";
import { updateDataApi } from "../api/passwordInsurances/updateData";

const PasswordsDoctors = ({ data, doctorData, insurances }) => {
  const [rows, setRows] = useState(data);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [insurance, setInsurance] = useState("");
  const [insuranceList, setInsuranceList] = useState([]);
  const [url, setUrl] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [mappedRows, setMappedRows] = useState([]);
  const [doctor, setDoctor] = useState(doctorData);

  const headers = ["Aseguradora", , "Usuario", "Contraseña", "Observaciones", "Acciones"];

  useEffect(() => {
    const mappedRows = rows.map((row) => [
      row.insurance,
      row.user,
      row.password,
      row.url,
      <div>
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDelete(row)}>
          <DeleteIcon />
        </IconButton>
      </div>,
    ]);
    setMappedRows(mappedRows);
  }, [rows]);

  const handleEdit = (row) => {
    setSelectedRow(row);
    setInsurance(row.insurance);
    setUrl(row.url);
    setUser(row.user);
    setPassword(row.password);
    setShowModal(true);
  };

  useEffect(() => {
    console.log("rows123", data);
    setRows(data);
  }, [data]);

  useEffect(() => {
    setDoctor(doctorData);
  }, [doctorData]);

  useEffect(() => {
    setInsuranceList(insurances);
    console.log("insurances", insurances);
  }, [insurances]);


  const handleSubmit = async () => {
    console.log("submit");
    const newRow = {
      id: selectedRow ? selectedRow.id : rows.length + 1,
      entity: selectedRow ? selectedRow.entity : 1,
      insurance: insurance.id,
      url,
      user,
      password,
    };
    if (selectedRow) {
      console.log("selectedRow");
      //setRows(rows.map((row) => (row.id === selectedRow.id ? newRow : row)));
      newRow.doctor_id = doctor.id;
      await updateDataApi(newRow, selectedRow.id);
    } else {
      newRow.doctor_id = doctor.id;
      console.log("newRow", newRow);
      await createDataApi(newRow);
      setRows([...rows, newRow]);
    }
    window.location.reload();
    handleClose();
  };

  const handleClose = () => {
    setInsurance("");
    setUrl("");
    setUser("");
    setPassword("");
    setSelectedRow(null);
    setShowModal(false);
  };

  const handleDelete = (row) => {
    setRows(rows.filter((r) => r.id !== row.id));
  };

  const rowsPerPageOptions = [5, 10, 25];


  const handleInsuranceChange = (event, newValue) => {
    console.log(newValue);
    setInsurance(newValue);
  };


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowModal(true)}
        style={{ marginBottom: 20 }}
      >
        Agregar
      </Button>
      <PasswordsInsuranceTable
        headers={headers}
        rows={mappedRows}
        rowsPerPageOptions={rowsPerPageOptions}
      />
      <Dialog open={showModal} onClose={handleClose}>
        <DialogTitle>{selectedRow ? "Editar" : "Agregar"}</DialogTitle>
        <DialogContent>
          {/* <TextField
            label="Aseguradora"
            value={insurance}
            onChange={(e) => setInsurance(e.target.value)}
            fullWidth
          /> */}

          <SelectController
            label="Aseguradora"
            options={insuranceList}
            value={insurance?.label}
            onChange={(e, newValue) => handleInsuranceChange(e, newValue)}
          />
          <TextField
            label="Usuario"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            fullWidth
          />
          <TextField
            label="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
          <TextField
            label="Observaciones"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default PasswordsDoctors;
