import React from "react";
import { Grid, InputLabel, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";

const InputController = ({ label, value, onChange, rows, multiline }) => (
  <Grid item xs={6} lg={6}>
    <InputLabel>{label}</InputLabel>
    <TextField fullWidth value={value} multiline={multiline} rows={3} InputLabelProps={{ shrink: true }}
      onChange={onChange} color="secondary" />
  </Grid>
);

const SelectController = ({ label, options, value, onChange }) => (
  <Grid item xs={6} lg={6}>
    <InputLabel>{label}</InputLabel>
    <Autocomplete
      options={options}
      value={value}
      onChange={onChange}
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} />}
    />
  </Grid>
);

export { InputController, SelectController };
