import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setUserInfo } from "../slice/userSlice";
import { useNavigate } from "react-router-dom";
import authService from "../auth/authService";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  Hotel as HotelIcon,
  LocalLibrary as LocalLibraryIcon,
  LocalHospital as LocalHospitalIcon,
  Business as BusinessIcon,
  InsertChart as InsertChartIcon,
  PermIdentity as PermIdentityIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
const drawerWidth = 250;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
    },
  }),
}));

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: drawerWidth,
    overflowX: "hidden",
  }),
  ...(!open && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  }),
}));

const MainContent = styled("main")(({ theme, open }) => ({
  flexGrow: 1,
  p: 3,
  marginLeft: open ? drawerWidth - 230 : 10,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: 10, // Ajustar el margen superior para evitar la superposición con la AppBar
}));

export default function Home({ children, title }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);

  const handleDrawerOpen = () => {
    setOpen(true);
    console.log("handleDrawerOpen");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    console.log("handleDrawerClose");
  };

  const handleClick = () => {
    setOpen1(!open1);
  };

  const getData = async () => {
    const isLoggin = authService.isLoggedIn();
    if (!isLoggin) {
      navigate("/", { replace: true });
    } else {
      //TODO: Obtener datos persistentes del usuario
      const token = localStorage.getItem("token");
      dispatch(setUser(token));
      const userInfo = localStorage.getItem("userInfo");
      dispatch(setUserInfo(JSON.parse(userInfo)));
    }
  };

  useEffect(() => {
    if (!Object.keys(userInfo).length) {
      getData();
    }
  }, [userInfo]);

  useEffect(() => {
    const getData1 = async () => {
      await getData();
    };
    getData1();
  }, []);

  const logOut = () => {
    window.localStorage.clear();
    navigate("/");
  };

  const menuItemsAdmin = [
    //{path: '/dashboard', icon: <DashboardIcon/>, text: 'Dashboard'},
    { path: "/dashboard", icon: <DashboardIcon />, text: "Inicio" },
    { path: "/pacientes", icon: <HotelIcon />, text: "Registro Pacientes" },
    { path: "/doctors", icon: <LocalLibraryIcon />, text: "Médicos" },
    {
      path: "/specialties",
      icon: <LocalHospitalIcon />,
      text: "Especialidades",
    },
    { path: "/entities", icon: <BusinessIcon />, text: "Entidades" },
  ];

  const menuItemsDoctor = [
    { path: "/dashboard", icon: <DashboardIcon />, text: "Inicio" },
    { path: "/pacientes", icon: <HotelIcon />, text: "Registro Pacientes" },
  ];

  const reportItemsAdmin = [
    { text: "Por tabular", path: "/por-tabular" },
    { text: "Retabulación", path: "/retabulacion" },
    { text: "Tabulado", path: "/tabulado" },
    { text: "Cobranza", path: "/cobranza" },
    { text: "Pagados a Corte", path: "/pagados-corte" },
    { text: "Corte por Liquidar", path: "/corte-liquidar" },
    { text: "Corte Pagado", path: "/corte-pagado" },
  ];

  const reportItemsOperation = [
    { text: "Por tabular", path: "/por-tabular" },
    { text: "Retabulación", path: "/retabulacion" },
    { text: "Tabulado", path: "/tabulado" },
    { text: "Cobranza", path: "/cobranza" },
  ];

  const selectedMenuItems =
    userInfo.role_id === 1 || userInfo.role_id === 2
      ? menuItemsAdmin
      : menuItemsDoctor;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarStyled position="absolute" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 5, ...(open && { display: "none" }) }}
          >
            <MenuIcon style={{ color: "#FFF" }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={logOut}
            edge="end"
            sx={{ mt: -6, mr: 2 }}
            style={{ float: "right" }}
          >
            <ExitToAppIcon style={{ color: "#FFF" }} />
          </IconButton>
        </Box>
      </AppBarStyled>
      <DrawerStyled variant="permanent" open={open}>
        <DrawerHeader style={{ backgroundColor: "#1976d2" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "#FFF" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "#FFF" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {selectedMenuItems.map((item) => (
            <ListItemButton
              key={item.text}
              onClick={() => navigate(item.path)}
              open={open}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'center' : 'initial',
                px: 3
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center'
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          ))}
          <ListItemButton
            onClick={handleClick}
            icon={<InsertChartIcon />}
            text="Reportes"
            open={open}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <InsertChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reportes" sx={{ opacity: open ? 1 : 0 }} />
            {open1 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {userInfo.role_id === 1 ? (
                reportItemsAdmin.map((item, index) => (
                  <ListItemButton key={index} sx={{ pl: 4 }}>
                    <ListItemIcon onClick={() => navigate(item.path)}>
                      <InsertChartIcon />
                    </ListItemIcon>
                    <ListItemText
                      onClick={() => navigate(item.path)}
                      primary={item.text}
                    />
                  </ListItemButton>
                ))
              ) : (
                reportItemsOperation.map((item, index) => (
                  <ListItemButton key={index} sx={{ pl: 4 }}>
                    <ListItemIcon onClick={() => navigate(item.path)}>
                      <InsertChartIcon />
                    </ListItemIcon>
                    <ListItemText
                      onClick={() => navigate(item.path)}
                      primary={item.text}
                    />
                  </ListItemButton>
                ))
              )}
            </List>
          </Collapse>
        </List>
        <Divider />
        <List>
          {userInfo.role_id === 1 ? (
            <ListItemButton
              icon={<PermIdentityIcon />}
              text="Usuarios del sistema"
              open={open}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={() => navigate("/usuarios")}
              >
                <PermIdentityIcon />
              </ListItemIcon>
              <ListItemText
                primary="Usuarios del sistema"
                sx={{ opacity: open ? 1 : 0 }}
                onClick={() => navigate("/usuarios")}
              />
            </ListItemButton>
          )
            : null
          }
        </List>
      </DrawerStyled>
      <MainContent open={open}>
        <DrawerHeader />
        {children}
      </MainContent>
    </Box>
  );
}
