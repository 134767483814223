import React, {useState} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid} from '@mui/material';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {InputController, SelectController} from '../../atoms/AtomsForms';

const DynamicTableUsers = ({headers, rows, roles}) => {
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleRowClick = (row) => {
        setSelectedUser(row);
        setOpen(true);
    };

    const Actions = ({row, index}) => {
        return (
            <div>
                <IconButton color="primary" aria-label="edit">
                    <EditIcon/>
                </IconButton>
                <IconButton aria-label="delete">
                    <DeleteIcon style={{color: 'red'}}/>
                </IconButton>
            </div>
        );
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell key={index}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index} onClick={() => handleRowClick(row)}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.lastname || '-'}</TableCell>
                                <TableCell>{row.secondlastname || '-'}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.roles[0].slug}</TableCell>
                                <TableCell>Activo</TableCell>
                                <TableCell>
                                    <Actions row={row} index={index}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Editar Usuario</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={10} md={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <InputController label="Nombre(s)" value={selectedUser?.name}
                                                     onChange={(e) => setSelectedUser({
                                                         ...selectedUser,
                                                         name: e.target.value
                                                     })}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputController label="Apellido Paterno" value={selectedUser?.lastname}
                                                     onChange={(e) => setSelectedUser({
                                                         ...selectedUser,
                                                         lastname: e.target.value
                                                     })}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputController label="Apellido Materno" value={selectedUser?.secondlastname}
                                                     onChange={(e) => setSelectedUser({
                                                         ...selectedUser,
                                                         secondlastname: e.target.value
                                                     })}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputController label="Correo Electrónico" value={selectedUser?.email}
                                                     onChange={(e) => setSelectedUser({
                                                         ...selectedUser,
                                                         email: e.target.value
                                                     })}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputController label="Contraseña" value={selectedUser?.password}
                                                     onChange={(e) => setSelectedUser({
                                                         ...selectedUser,
                                                         password: e.target.value
                                                     })}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputController label="Confirmar Contraseña" value={selectedUser?.confirmPassword}
                                                     onChange={(e) => setSelectedUser({
                                                         ...selectedUser,
                                                         confirmPassword: e.target.value
                                                     })}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputController label="ID Token" value={selectedUser?.token}
                                                     onChange={(e) => setSelectedUser({
                                                         ...selectedUser,
                                                         token: e.target.value
                                                     })}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectController label="Rol" options={roles} value={selectedUser?.roles[0].slug}
                                                      onChange={(e, newValue) => setSelectedUser({
                                                          ...selectedUser,
                                                          roles: [{slug: newValue}]
                                                      })}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectController label="Estatus Actual" options={['Activo', 'Inactivo']}
                                                      value={selectedUser?.status}
                                                      onChange={(e, newValue) => setSelectedUser({
                                                          ...selectedUser,
                                                          status: newValue
                                                      })}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button onClick={() => {
                        // Aquí manejarías la lógica para actualizar el usuario
                        setOpen(false);
                    }}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DynamicTableUsers;
