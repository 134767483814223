import React from "react";
import {Link} from "react-router-dom";
import {Box} from "@mui/material";
import IconLogo from "../assets/img/logoAdministramedic.jpg";

const Logo = () => {
    return (
        <Box>
            <Link to="/">
                <Box sx={{width: '50%', height: '30%'}} component="img" src={IconLogo} alt="logo"/>
            </Link>
        </Box>
    );
};

export default Logo;
