import config from '../../config';
export const createEntityApi = async (entityData) => {
    console.log('createEntityApi');
    const token = localStorage.getItem('token');
    const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        Cookie: `XSRF-TOKEN=${token}`,
        'Content-Type': 'application/json',
    });
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(entityData),
        redirect: 'follow'
    };

    const response = await fetch(`${config.config.serverUrl}api/createEntity`, requestOptions);
    console.log('response', response);
    return response.json();
}
