import {createSlice} from "@reduxjs/toolkit";


export const doctorSlice = createSlice({
    name: "doctors",
    initialState: {
        doctors: {},
        isLoading: true,
    },
    reducers: {
        setDoctors: (state, action) => {
            state.doctors = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
});

export const {
    setDoctors,
    setIsLoading,
} = doctorSlice.actions;
export default doctorSlice.reducer;

