import config from "../../config";

export const patientApi = async (filter, page, rowsPerPage) => {
  console.log("filter", page);
  const token = localStorage.getItem("token");
  const myHeaders = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let sanitizedFilter = {};

  for (let key in filter) {
    if (filter[key] !== null && filter[key] !== "") {
      sanitizedFilter[key] = filter[key];
    }
  }

  // Agrega page y rowsPerPage a los parámetros de consulta
  sanitizedFilter.page = page + 1; // Asegúrate de que la página comienza en 1 en lugar de 0
  sanitizedFilter.rowsPerPage = rowsPerPage;

  let queryParams = new URLSearchParams(sanitizedFilter).toString();
  console.log("queryParams", queryParams);

  try {
    const response = await fetch(
      `${config.config.serverUrl}api/patients?${queryParams}`,
      requestOptions
    ).then((response) => response.json());
    return JSON.stringify(response);
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
