import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import PDFViewer from "./PDFViewer";
import { setFileApiPatient } from "../api/patient/setPatientFile";
import { getFileApiPatient } from "../api/patient/getFilePatient";
import EditIcon from '@mui/icons-material/Edit';

export const FileModalPatient = ({ doctor, doctor_files = [] }) => {
  console.log(doctor_files);
  const [descripcionFiles, setDescripcionFiles] = useState(
    doctor && doctor.doctor_files ? doctor.doctor_file.file : ""
  );
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [errors, setErrors] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(doctor_files || []);

  useEffect(() => {
    setUploadedFiles(doctor_files);
  }, [doctor_files]);

  const convertFileToBase64 = (file) => {
    //Convert pdf to base64
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (event, doctorId, description) => {
    event.preventDefault(); // Evita que la página se recargue
    console.log("handleSubmit");
    console.log("doctor", doctor.id);

    let fileBase64 = await convertFileToBase64(file);
    fileBase64 = fileBase64.split(",")[1];
    console.log("fileBase64", fileBase64);

    const response = await setFileApiPatient(fileBase64, doctor.id, descripcionFiles);
    console.log("response", response);
    window.location.reload();
  };

  const handleClose = () => {
    console.log("close");
    setOpen(!open);
  };

  const onDelete = (fileUrl) => {
    const [type, id] = fileUrl.split("/").slice(-2);
    console.log("type", type, "id", id);
    //TODO: Eliminar el archivo
  };

  const showFile = async (file) => {
    //quit .data from file
    let fileFinal = file.split(".dat")[0];
    console.log("fileFinal", fileFinal);
    let url = await getFileApiPatient(fileFinal);
    console.log("url", url);
    setPdfFile(url);
    setOpen(true);
    // let url = await getFileApi(patientData.id, type);
    // setPdfFile(url);
    // setType(type);
    // setOpen(true);
  };

  const changeName = (file) => {
    console.log("changeName", file);
  }

  return (
    <div>
      <PDFViewer
        open={open}
        onClose={handleClose}
        pdfFile={pdfFile}
        onDelete={onDelete}
      />
      <form onSubmit={handleSubmit}>
        <TextField
          label="Descripción:"
          variant="outlined"
          value={descripcionFiles}
          onChange={(event) => setDescripcionFiles(event.target.value)}
          InputProps={{
            startAdornment: <DescriptionIcon />,
          }}
        />

        <Button variant="contained" component="label">
          Archivo:
          <input
            type="file"
            hidden
            onChange={(event) => setFile(event.target.files[0])}
          />
        </Button>

        <Button type="submit">Guardar</Button>
      </form>

      {errors.length > 0 &&
        errors.map((error, index) => (
          <div key={index} style={{ color: "red" }}>
            {error}
          </div>
        ))}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Estatus</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadedFiles.map((uploadedFile, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{uploadedFile.description}</TableCell>
                <TableCell>
                  {uploadedFile.status === 1 ? "Activo" : "Inactivo"}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => showFile(uploadedFile.file)}
                    target="_blank"
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => changeName(uploadedFile.file)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
