import Home from "./Home";
import { Box, Fab, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import { InputController, SelectController } from "../atoms/AtomsForms";
import { useState } from "react";
import { createEntityApi } from "../api/entities/createEntity";
import { useNavigate } from "react-router-dom";


export const CreateEntity = () => {
    const [entity, setEntity] = useState({ type_id: 4 });
    const navigate = useNavigate();

    const handleSave = async () => {
        if (!entity.name) {
            alert('El nombre es requerido');
            return;
        } else {
            const response = await createEntityApi(entity);
            console.log('response', response);
            if (!response.error) {
                alert('Entidad creada');
                navigate(`/entity?id=${response.entity.id}`)
            }
        }
        console.log('save', entity);
    }

    return (
        <Home
            title="Entidades"
            children={
                <>
                    <Fab color="primary" aria-label="add" style={{
                        margin: 0,
                        top: 'auto',
                        right: 20,
                        bottom: 20,
                        left: 'auto',
                        position: 'fixed',
                    }}>
                        <IconButton aria-label="save" style={{ color: "white" }}
                            onClick={() => handleSave()}>
                            <SaveIcon />
                        </IconButton>
                    </Fab>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Nombre"
                                        value={entity?.name}
                                        onChange={(e) => setEntity({ ...entity, name: e.target.value.toUpperCase() })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <SelectController
                                        label="Tipo de entidad"
                                        options={[
                                            { id: 4, label: 'Aseguradora' },
                                            { id: 5, label: 'Hospital' },
                                            { id: 6, label: 'Empresas' },
                                        ]}
                                        value={
                                            entity && entity.type_id === 5
                                                ? { id: 5, label: 'Hospital' }
                                                : entity && entity.type_id === 6
                                                    ? { id: 6, label: 'Empresas' }
                                                    : { id: 4, label: 'Aseguradora' }  // default to 4
                                        }
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setEntity({ ...entity, type_id: newValue.id });
                                            }
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Razón social"
                                        value={entity?.business_name}
                                        onChange={(e) => setEntity({ ...entity, business_name: e.target.value.toUpperCase()})}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="RFC"
                                        value={entity?.rfc}
                                        onChange={(e) => setEntity({ ...entity, rfc: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Calle"
                                        value={entity?.street}
                                        onChange={(e) => setEntity({ ...entity, street: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Colonia"
                                        value={entity?.suburb}
                                        onChange={(e) => setEntity({ ...entity, suburb: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Municipio"
                                        value={entity?.town}
                                        onChange={(e) => setEntity({ ...entity, town: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Estado"
                                        value={entity?.state}
                                        onChange={(e) => setEntity({ ...entity, state: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Número exterior"
                                        value={entity?.external_number}
                                        onChange={(e) => setEntity({ ...entity, external_number: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Número interior"
                                        value={entity?.internal_number}
                                        onChange={(e) => setEntity({ ...entity, internal_number: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Código postal"
                                        value={entity?.zip}
                                        onChange={(e) => setEntity({ ...entity, zip: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Ciudad"
                                        value={entity?.city}
                                        onChange={(e) => setEntity({ ...entity, city: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="País"
                                        value={entity?.country}
                                        onChange={(e) => setEntity({ ...entity, country: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Contacto"
                                        value={entity?.contact}
                                        onChange={(e) => setEntity({ ...entity, contact: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box display="flex" justifyContent="center">
                                    <InputController
                                        label="Dirección de recepción"
                                        fullWidth={true}
                                        value={entity?.address_reception}
                                        onChange={(e) => setEntity({ ...entity, address_reception: e.target.value })}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        />
    );
};
