import React from 'react';
import {Button, Box} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from "@mui/material/IconButton";

const FileUploader = ({ onUpload, fileId, accept = '.pdf,.xml', ...props }) => {
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (event) => {
        console.log('handleFileChange', event.target.files[0], fileId);
        const file = event.target.files[0];
        if (onUpload) {
            if (file.type === 'application/pdf') {
                const fileBase64 = await convertToBase64(file);
                //Quit data:application/pdf;base64,
                onUpload(fileBase64.substring(28), fileId);
                //onUpload(fileBase64, type);
            } else {
                let xml = await convertToBase64(file);
                //Quit data:application/xml;base64,
                xml = xml.substring(28);
                onUpload(xml, fileId);
                //onUpload(file, type);
            }
        }
    };


    return (
        <Box component="span" {...props}>
            <input
                accept={accept}
                style={{ display: 'none' }}
                id={`file-uploader-${fileId}`}
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor={`file-uploader-${fileId}`}>
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <FileUploadIcon />
                </IconButton>
            </label>
        </Box>
    );
};

export default FileUploader;
