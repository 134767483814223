import React, {useEffect, useState} from "react";
import Home from "./Home";
import {useSelector} from "react-redux";
import {getUsersApi} from "../api/users/getUsers";
import DynamicTableUsers from "../components/tables/userTable";

export const Users = () => {
    const {userInfo} = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);

    const headers = [
        "Nombre",
        "Apellido Paterno",
        "Apellido Materno",
        "Correo",
        "Rol",
        "Estatus",
        "Acciones"
    ];

    const fetchData = async () => {
        setIsLoading(true);
        let response = await getUsersApi();
        response = JSON.parse(response);
        setUsers(response.users.data);
        setRoles(response.roles);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Home
                children={
                    <>
                        {!isLoading && users ? (
                            <DynamicTableUsers
                                headers={headers}
                                rows={users}
                                roles={roles}
                            />
                        ) : (
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </>
                }
                title={"Usuarios"}
            />
        </div>
    )
}
