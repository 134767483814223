import config from "../../config";

export const createSpecialtyApi = async (data) => {
    console.log('createSpecialtyApi', data);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        Cookie: `XSRF-TOKEN=${token}`,
        'Content-Type': 'application/json',
    });

    let raw = JSON.stringify({
        name: data[1].toUpperCase(),
        status: data[2] === "Activo" ? 1 : 0
    });

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(`${config.config.serverUrl}api/createSpecialty`, requestOptions)
    return response.json();
}
