import React, {useState} from 'react';
import {Card, CardContent, Grid, Typography, Modal, Box, IconButton} from '@mui/material';
import {CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material';

const TableCards = ({data}) => {
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedCards, setSelectedCards] = useState([]);

    const handleOpen = (item) => {
        setModalContent(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMouseEnter = (index) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    const handleCardSelect = (index) => {
        if (selectedCards.includes(index)) {
            setSelectedCards(selectedCards.filter((item) => item !== index));
        } else {
            setSelectedCards([...selectedCards, index]);
        }
    };

    return (
        <>
            <Typography variant="subtitle1" gutterBottom>
                Total de pacientes: {data.length}
            </Typography>
            <Grid container spacing={2}>
            {data && data.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                        sx={{
                            height: '250px',
                            cursor: 'pointer',
                            backgroundColor: 'background.paper',
                            borderRadius: 2,
                            transition: 'box-shadow 0.3s',
                            boxShadow: activeIndex === index ? 5 : 1,
                            position: 'relative',
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleOpen(item)}
                    >
                        <CardContent sx={{mt: 4, flexGrow: 1}}>
                            <Typography variant="h6" sx={{fontWeight: 500, mb: 1}}>Paciente: {item.patient}</Typography>
                            <Typography variant="body2" color="text.secondary">Consecutivo: {item.order}</Typography>
                            <Typography variant="body2" color="text.secondary">Fecha de
                                atención: {item.date_attention}</Typography>
                            <Typography variant="body2"
                                        color="text.secondary">Doctor: {item.doctor_business_name}</Typography>
                            <Typography variant="body2"
                                        color="text.secondary">Creado: {new Date(item.created_at).toLocaleDateString("es-ES", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            })}</Typography>
                        </CardContent>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCardSelect(index);
                            }}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                backgroundColor: 'background.paper',
                                '&:hover': {
                                    backgroundColor: 'background.paper',
                                },
                            }}
                        >
                            {selectedCards.includes(index) ? <CheckBox/> : <CheckBoxOutlineBlank/>}
                        </IconButton>
                    </Card>
                </Grid>

            ))}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: '50%',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    {modalContent && (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2"
                                        sx={{fontWeight: 500, mb: 1}}>
                                Paciente: {modalContent.patient}
                            </Typography>
                            <Typography id="modal-modal-description" variant="body2" color="text.secondary">
                                Fecha de atención: {modalContent.date_attention}
                            </Typography>
                            <Typography variant="body2"
                                        color="text.secondary">Doctor: {modalContent.doctor_business_name}</Typography>
                            <Typography variant="body2"
                                        color="text.secondary">Creado: {new Date(modalContent.created_at).toLocaleDateString("es-ES", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            })}
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Grid>
        </>
    );
};

export default TableCards;
