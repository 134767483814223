import React, { useState, useRef } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination, IconButton, Box, Checkbox, Button, Typography, DialogContentText, TextField, Dialog, DialogContent, DialogActions,
} from "@mui/material";
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from "@mui/icons-material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Description, PictureAsPdf } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { changeToCortePorLiquidarApi } from "../../api/reportes/toCortePorLiquidarApi";
import { changeToCortePagadoApi } from "../../api/reportes/toCortePagadoApi";
import dayjs from "dayjs"; // Aquí está el cambio
import { CheckCircle, Cancel } from "@mui/icons-material";
import configApp from "../../config";
import logoAdministramedic from "../../assets/img/logoAdministramedic.jpg";
import not from "../../assets/images/not.png";
import yes from "../../assets/images/yes.png";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";


const DynamicTableReport = ({ headers, rows, rowsPerPageOptions, pagination, onPageChange, amounts, onRowsPerPageChange, rowsPerPage, type, page }) => {
  const [checkedRows, setCheckedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [actualBatch, setActualBatch] = useState(null);
  const { userInfo } = useSelector((state) => state.user);

  const transformedRows = rows.map((row) =>
    row.map((cell, cellIndex) =>
      headers[cellIndex + 1] === "REP" ? (cell ? "SI" : "NO") : cell
    )
  );
  const displayedRows = transformedRows.map((row) =>
    row.filter((_, index) => headers[index] !== "id")
  );
  const displayedHeaders = headers.filter((header) => header !== "id");
  const navigate = useNavigate();
  const {
    first_page_url,
    last_page_url,
    next_page_url,
    per_page,
    total,
    prev_page_url,
  } = pagination;

  const tableRef = useRef(null);

  const exportToPDF = () => {
    const doc = new jsPDF("landscape", "pt", "a4");

    // Agregar la imagen al PDF
    doc.addImage(logoAdministramedic, 'JPEG', 20, 20, 100, 50); // Puedes ajustar las coordenadas y dimensiones según tus necesidades

    const selectedRows = rows.filter((_, rowIndex) =>
      checkedRows.includes(rowIndex)
    );

    // Generar los datos de las filas
    let rowData = selectedRows.map((row, rowIndex) =>
      [{ content: rowIndex + 1 }].concat(
        row.map((cell, cellIndex) => {
          const header = headers[cellIndex];
          return header !== "id" ? { content: cell } : null;
        })
      )
    );

    if (amounts) {
      const amountValues = Object.values(amounts);

      let totalRow = Array(headers.length).fill({ content: "" });
      totalRow[7] = { content: "Total:" };
      for (let i = 0; i < 4; i++) {
        totalRow[8 + i] = { content: amountValues[i] || "" };
      }
      rowData.push(totalRow);

      let ivaRow = Array(headers.length).fill({ content: "" });
      ivaRow[10] = { content: "IVA:" };
      ivaRow[11] = { content: amountValues[4] || "" };
      rowData.push(ivaRow);

      let commissionRow = Array(headers.length).fill({ content: "" });
      commissionRow[10] = { content: "Total comisión:" };
      commissionRow[11] = { content: amountValues[5] || "" };
      rowData.push(commissionRow);
    }

    const filteredRowData = rowData.map((row) =>
      row.map((cell, cellIndex) => {
        if (headers[cellIndex + 1] === "REP") {
          console.log("cell.content", cell);
          // Si la celda contiene una fecha (o cualquier contenido), agregar un punto
          console.log("cell.content", cell.content.length);
          return { content: cell.content.length > 0 ? '-' : '' };
        }
        return cell;
      })
    );

    doc.autoTable({
      head: [headers.filter((header) => header !== "id")],
      body: filteredRowData,
      startY: 80, // Ajusta según la posición de la imagen
      styles: {
        fontSize: 8,
        overflow: "linebreak",
        columnWidth: "auto",
        cellPadding: 4,
      },
      margin: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
      didDrawCell: function (data) {
        // Si la celda corresponde a la columna "REP"
        if (data.column.index === headers.indexOf("REP") - 1) {
          const { x, y, cellWidth, cellHeight } = data.cell;

          // Si es la fila del encabezado, se mantiene el texto
          if (data.row.index === 0 && data.cell.text[0] === "REP") {
            return;
          }

          // Decidir qué imagen dibujar basado en si hay un punto en el contenido de la celda
          const image = data.cell.text[0] === '-' ? yes : not;

          // Limpiar el texto de la celda
          data.cell.text = [''];

          // Dibujar la imagen en la celda
          doc.addImage(image, 'PNG', x, y, 15, 15);
        }
        // Personalizar el estilo de las celdas de amounts
        else if (data.row.index >= selectedRows.length && data.column.index >= 8) {
          data.cell.styles.fontSize = 12; // Tamaño de letra más grande
          data.cell.styles.fontStyle = 'bold'; // Estilo negrita
        }
      },
    });

    // Agregar el texto de la cuenta al final del documento
    doc.setFontSize(18);
    if (type === 52) {
      doc.text("AFIRME Cuenta.- 011081002292  Clabe.- 062580110810022927 Florido Villarreal y asociados", 20, doc.internal.pageSize.height - 30);
    }
    doc.save("reporte.pdf");
  };

  const exportToExcel = () => {
    // Crear un libro de trabajo
    const wb = XLSX.utils.book_new();

    // Obtiene las filas seleccionadas
    const selectedRows = rows.filter((_, rowIndex) =>
      checkedRows.includes(rowIndex)
    );

    // Generar los datos de las filas
    let rowData = selectedRows.map((row, rowIndex) =>
      [{ content: rowIndex + 1 }].concat(
        row.map((cell, cellIndex) => {
          const header = headers[cellIndex];
          return header !== "id" ? cell : null;
        })
      )
    );

    if (amounts) {
      const amountValues = Object.values(amounts);

      let totalRow = Array(headers.length).fill("");
      totalRow[7] = "Total:";
      for (let i = 0; i < 4; i++) {
        totalRow[8 + i] = amountValues[i] || "";
      }
      rowData.push(totalRow);

      let ivaRow = Array(headers.length).fill("");
      ivaRow[10] = "IVA:";
      ivaRow[11] = amountValues[4] || "";
      rowData.push(ivaRow);

      let commissionRow = Array(headers.length).fill("");
      commissionRow[10] = "Total comisión:";
      commissionRow[11] = amountValues[5] || "";
      rowData.push(commissionRow);
    }

    const filteredRowData = rowData.map((row) =>
      row.filter((cell) => cell !== null)
    );

    // Añadir encabezado
    filteredRowData.unshift(headers.filter((header) => header !== "id"));

    // Crear una hoja de trabajo con los datos
    const ws = XLSX.utils.aoa_to_sheet(filteredRowData);

    // Calcula el ancho máximo para cada columna
    const maxWidths = headers.map((_, index) =>
      Math.max(...filteredRowData.map(row => (row[index] ? String(row[index]).length : 0)))
    );

    if (!ws['!cols']) {
      ws['!cols'] = [];
    }

    // Establece el ancho para cada columna en la hoja de trabajo
    maxWidths.forEach((width, index) => {
      ws['!cols'][index] = { wch: width };
    });

    // Añadir la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Exportar el libro de trabajo a un archivo Excel con la fecha y hora de hoy
    XLSX.writeFile(wb, `reporte-${new Date().toLocaleString()}.xlsx`);
  };

  const exportToExcelWithoutREP = () => {
    const wb = XLSX.utils.book_new();

    // Índices de las columnas a excluir
    const idIndex = 0; // 'id'
    const consecutivoIndex = 4; // 'Consecutivo'
    const comisionIndex = headers.length - 1; // 'Comisión' si está presente

    // Excluir las columnas 'id', 'Consecutivo' y 'Comisión' del arreglo de encabezados
    let filteredHeaders = headers.filter((_, index) => {
      return index !== idIndex && index !== consecutivoIndex && (userInfo.role_id !== 1 || index !== comisionIndex);
    });

    // Filtrar las filas excluyendo las columnas 'id', 'Consecutivo' y 'Comisión'
    const filteredRows = rows.map(row => {
      let newRow = row.filter((_, index) => {
        return index !== idIndex && index !== consecutivoIndex && (userInfo.role_id !== 1 || index !== comisionIndex);
      });
      // Borrar el último elemento de la fila
      newRow.pop();
      // Insertar un elemento vacío al principio para correr la posición hacia adelante
      newRow.unshift("");
      return newRow;
    });

    // Crear una hoja de trabajo con los datos
    const ws = XLSX.utils.aoa_to_sheet([filteredHeaders, ...filteredRows]);

    // Calcula el ancho máximo para cada columna
    const maxWidths = filteredHeaders.map((_, index) =>
      Math.max(...filteredRows.map(row => (row[index + 1] ? String(row[index + 1]).length : 0)))
    );

    if (!ws['!cols']) {
      ws['!cols'] = [];
    }

    // Establece el ancho para cada columna en la hoja de trabajo
    maxWidths.forEach((width, index) => {
      ws['!cols'][index] = { wch: width };
    });

    // Añadir la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Exportar el libro de trabajo a un archivo Excel con la fecha y hora de hoy
    XLSX.writeFile(wb, `reporte-${new Date().toLocaleString()}.xlsx`);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row, index) => index);
      setCheckedRows(newSelecteds);
      return;
    }
    setCheckedRows([]);
  };

  const handleCheckboxClick = (event, rowIndex) => {
    event.stopPropagation();
    const isChecked = checkedRows.includes(rowIndex);
    if (!isChecked) {
      setCheckedRows([...checkedRows, rowIndex]);
    } else {
      const updatedCheckedRows = checkedRows.filter((row) => row !== rowIndex);
      setCheckedRows(updatedCheckedRows);
    }
  };

  const handleRowClick = (row, rowIndex) => {
    navigate(`/patient?id=${rows[rowIndex][0]}`);
  };

  const totalPages = Math.ceil(total / per_page);

  const sendCortePorLiquidar = async () => {
    const selectedRows = rows.filter((_, rowIndex) =>
      checkedRows.includes(rowIndex)
    );
    let data;
    if (selectedRows.length > 0) {
      data = selectedRows.map((row) => row[0]);
    }
    let response = await changeToCortePorLiquidarApi(data);
    response = JSON.parse(response);
    if (!response.error) {
      alert("Pacientes cambiados a corte por liquidar");
      window.location.reload();
    } else {
      alert("Error al cambiar a corte por liquidar, intente de nuevo");
    }
  };

  const sendCortePagado1 = (batch) => {
    console.log("batch", batch);
    setOpenModal(true);
    setActualBatch(batch);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleConfirm = async () => {
    setOpenModal(false);

    const selectedRows = rows.filter((_, rowIndex) =>
      checkedRows.includes(rowIndex)
    );
    let data;
    if (selectedRows.length > 0) {
      data = selectedRows.map((row) => row[0]);
    }

    console.log("data", data);

    let response = await changeToCortePorLiquidarApi(data, selectedDate);
    response = JSON.parse(response);
    if (!response.error) {
      alert("Pacientes cambiados a corte por liquidar");
      window.location.reload();
    } else {
      alert(response.message);
      //alert("Error al cambiar a corte por liquidar, intente de nuevo");
    }
  };

  const sendCortePagado = async () => {
    const selectedRows = rows.filter((_, rowIndex) =>
      checkedRows.includes(rowIndex)
    );
    let data;
    if (selectedRows.length > 0) {
      data = selectedRows.map((row) => row[0]);
    }
    let response = await changeToCortePagadoApi(data);
    response = JSON.parse(response);
    if (!response.error) {
      alert("Pacientes cambiados a corte pagado");
      window.location.reload();
    } else {
      alert("Error al cambiar a corte pagado, intente de nuevo");
    }
  };

  const getDateColor = (date) => {
    console.log("date", date);
    const today = dayjs();
    //const registerDate = dayjs(date, "DD/MM/YYYY");
    const diffDays = today.diff(date, "days");
    console.log("diffDays", diffDays);

    if (diffDays <= 15) {
      return "green";
    } else if (diffDays > 15 && diffDays <= 30) {
      return "orange";
    } else {
      return "red";
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <DialogContentText>Selecciona una fecha de corte</DialogContentText>
          <TextField
            fullWidth
            id="filled-search"
            label=""
            type="date"
            value={selectedDate}
            InputLabelProps={{ shrink: true }}
            onChange={handleDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Box style={{ paddingBottom: 50 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "10px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => exportToPDF()}
            style={{
              marginRight: "10px",
              backgroundColor: "red",
              color: "white",
            }}
            startIcon={<PictureAsPdf />}
          >
            <Typography variant="button">Exportar PDF</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => exportToExcel()}
            style={{
              marginRight: "10px",
              backgroundColor: "green",
              color: "white",
            }}
            startIcon={<Description />}
          >
            <Typography variant="button">Exportar Excel</Typography>
          </Button>


          {type === 52 ? (
            <Box>
              <Button
                variant="contained"
                onClick={exportToExcelWithoutREP}
                style={{
                  marginRight: "10px",
                  backgroundColor: "green",
                  color: "white",
                }}
                startIcon={<Description />}
              >
                <Typography variant="button">Exportar complementos faltantes</Typography>
              </Button>
              <Button
                variant="contained"
                onClick={() => sendCortePagado1()}
                style={{
                  marginRight: "10px",
                  color: "white",
                }}
                startIcon={<DoubleArrowIcon />}
              >
                <Typography variant="button">Corte por liquidar</Typography>
              </Button>
            </Box>
          ) : null}
          {type === 53 ? (
            <Button
              variant="contained"
              onClick={() => sendCortePagado()}
              style={{
                marginRight: "10px",
                color: "white",
              }}
              startIcon={<DoubleArrowIcon />}
            >
              <Typography variant="button">Corte pagado</Typography>
            </Button>
          ) : null}
          {type === 53 ? (
            <Button
              variant="contained"
              onClick={() => sendCortePagado()}
              style={{
                marginRight: "10px",
                color: "white",
              }}
              startIcon={<DoubleArrowIcon />}
            >
              <Typography variant="button">Corte pagado</Typography>
            </Button>
          ) : null}
        </Box>
        <TableContainer component={Paper}>
          <Table ref={tableRef}>
            <TableHead
              sx={{
                backgroundColor: configApp.styles.principalColor,
              }}
            >
              <TableRow>
                <TableCell>
                  <Checkbox
                    sx={{ color: "black" }}
                    indeterminate={
                      checkedRows.length > 0 && checkedRows.length < rows.length
                    }
                    checked={
                      rows.length > 0 && checkedRows.length === rows.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                {headers.map((header, index) =>
                  header !== "id" ? (
                    <TableCell
                      key={index}
                      style={{ textAlign: "center", color: "black" }}
                    >
                      {header}
                    </TableCell>
                  ) : null
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  onClick={() => handleRowClick(row, rowIndex)}
                >
                  <TableCell onClick={(event) => event.stopPropagation()}>
                    <Checkbox
                      type="checkbox"
                      checked={checkedRows.includes(rowIndex)}
                      onChange={(event) => handleCheckboxClick(event, rowIndex)}
                    />
                  </TableCell>
                  <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (displayedHeaders[cellIndex + 1] === "REP") {
                      return (
                        <TableCell key={cellIndex}>
                          {cell === "SI" ? (
                            <CheckCircle style={{ color: "green" }} />
                          ) : (
                            <Cancel style={{ color: "red" }} />
                          )}
                        </TableCell>
                      );
                    } else if (
                      displayedHeaders[cellIndex + 1] === "Fecha de registro" &&
                      type === 47
                    ) {
                      const dateColor = getDateColor(cell);
                      return (
                        <TableCell key={cellIndex} style={{ color: dateColor }}>
                          {cell}
                        </TableCell>
                      );
                    } else {
                      return <TableCell key={cellIndex}>{cell}</TableCell>;
                    }
                  })}
                </TableRow>
              ))}
              {amounts && (
                <TableRow>
                  {[...Array(8)].map((_, index) => (
                    <TableCell key={index} />
                  ))}
                  <TableCell>Total:</TableCell>
                  {Object.values(amounts).map((amount, index) => {
                    if (index < 4) {
                      return <TableCell key={index}>{amount}</TableCell>;
                    }
                    return null;
                  })}
                </TableRow>
              )}
              {amounts && Object.values(amounts).length > 0 && (
                <>
                  <TableRow>
                    {[...Array(11)].map((_, index) => (
                      <TableCell key={index} />
                    ))}
                    <TableCell>IVA:</TableCell>
                    {Object.values(amounts).map((amount, index) => {
                      if (index === 4) {
                        return <TableCell key={index}>{amount}</TableCell>;
                      }
                      return null;
                    })}
                  </TableRow>
                </>
              )}
              {amounts && Object.values(amounts).length > 0 && (
                <>
                  <TableRow>
                    {[...Array(11)].map((_, index) => (
                      <TableCell key={index} />
                    ))}
                    <TableCell>Total comisión:</TableCell>
                    {Object.values(amounts).map((amount, index) => {
                      if (index === 5) {
                        return <TableCell key={index}>{amount}</TableCell>;
                      }
                      return null;
                    })}
                  </TableRow>
                </>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Registros por página",
                    },
                    native: true,
                  }}
                  ActionsComponent={({
                    count,
                    page,
                    rowsPerPage,
                    onPageChange,
                  }) => (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <IconButton
                        onClick={(event) => onPageChange(event, 0, rowsPerPage)}
                        disabled={page === 0 || !first_page_url}
                      >
                        <FirstPage />
                      </IconButton>
                      <IconButton
                        onClick={(event) =>
                          onPageChange(event, page - 1, rowsPerPage)
                        }
                        disabled={page === 0 || !prev_page_url}
                      >
                        <NavigateBefore />
                      </IconButton>
                      <IconButton
                        onClick={(event) =>
                          onPageChange(event, page + 1, rowsPerPage)
                        }
                        disabled={page >= totalPages - 1 || !next_page_url}
                      >
                        <NavigateNext />
                      </IconButton>
                      <IconButton
                        onClick={(event) =>
                          onPageChange(event, totalPages - 1, rowsPerPage)
                        }
                        disabled={page >= totalPages - 1 || !last_page_url}
                      >
                        <LastPage />
                      </IconButton>
                    </div>
                  )}
                  labelRowsPerPage="Pacientes por página:"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default DynamicTableReport;
