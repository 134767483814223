import config from "../../config";

export const changeToCortePorLiquidarApi = async (patients, date) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Necesario para enviar el cuerpo JSON
  });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ patients: patients, date: date }), // Enviaremos la lista de pacientes como cuerpo de la solicitud
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${config.config.serverUrl}api/to-corte-por-liquidar`,
      requestOptions
    ).then((response) => response.json());
    return JSON.stringify(response);
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
