import config from "../../config";

export const setFileApiPatient = async (file, doctorId, description) => {
  const token = localStorage.getItem("token");
  console.log("setFileApi", "url", `/saveFilePatient`, token);

  const myHeaders = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const formData = new FormData();
  formData.append("id", doctorId);
  formData.append("description", description);
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  console.log(`${config.config.serverUrl}api/saveFilePatient/${doctorId}`);

  try {
    const response = await fetch(
      `${config.config.serverUrl}api/patients/saveFilePatient/${doctorId}`,
      requestOptions
    );
    console.log("response", response.message);
    if (response.ok) {
      console.log("El archivo se ha guardado correctamente.");
    } else {
      console.log(
        "Error al guardar el archivo. Código de respuesta:",
        response.status
      );
      return null;
    }
  } catch (error) {
    console.log("Error en la solicitud:", error);
    return null;
  }
};
