import React, { useEffect, useState } from 'react';
import Home from "./Home";
import useWindowDimensions from "../functions/useWindowDimensions";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Skeleton, TextField } from "@mui/material";
import DynamicTable from "../components/tables/DynamicTable";
import { getEntitiesApi } from "../api/entities/getEntities";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box } from '@mui/system';

export const Entity = () => {
    const rowsPerPageOptions = [20, 50, 100];
    const { height, width } = useWindowDimensions();
    const [entities, setEntities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [filteredEntities, setFilteredEntities] = useState([]);
    const headers = ["id", "Entidad", "Razón Social", "RFC", "Estatus"];
    const navigate = useNavigate();

    const handleDoctorRowClick = (selectedEntity) => {
        navigate(`/entity?id=${selectedEntity[0]}`)
    };

    const extractSpecialtyInfo = ({ id, name, business_name, rfc, status }) => [
        id,
        name,
        business_name,
        rfc,
        status === 1 ? "Activo" : "Inactivo",
    ];

    const handleCreate = () => {
        console.log('create');
        navigate('/createEntity');
    }

    useEffect(() => {
        const fetchEntities = async () => {
            setIsLoading(true);
            try {
                const entityData = await getEntitiesApi();
                console.log('entities', entityData);
                if (entityData && entityData.entities) {
                    const initialEntities = entityData.entities.map(extractSpecialtyInfo);
                    setEntities(initialEntities);
                    setFilteredEntities(initialEntities);  // Establece las entidades filtradas a las entidades iniciales
                } else {
                    console.error("Error: No se pudo obtener la información de las entidades.");
                }
            } catch (error) {
                console.error("Error al obtener las entidades:", error);
            }
            setIsLoading(false);
        };

        fetchEntities();
    }, []);

    const filterEntities = () => {
        const filtered = entities.filter(entity =>
            entity &&
            entity[1] && entity[1].toLowerCase().includes(searchTerm.toLowerCase())  // Busca en el campo 'Entidad'
        );
        setFilteredEntities(filtered);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        filterEntities();
    };

    const resetFilters = () => {
        setSearchTerm('');
        setFilteredEntities(entities);  // Restablece las entidades filtradas a las entidades originales
    };

    return (
        <Home
            title="Entidades"
            children={
                <>
                    {isLoading ? (
                        <Skeleton variant="rectangular" width={width / 1.1} height={height / 1.2} />
                    ) : (
                        <>
                            <Fab color="primary" aria-label="add" style={{
                                margin: 0,
                                top: 'auto',
                                right: 20,
                                bottom: 20,
                                left: 'auto',
                                position: 'fixed',
                            }}>
                                <IconButton aria-label="save" style={{ color: "white" }}
                                    onClick={() => handleCreate()}>
                                    <AddIcon />
                                </IconButton>
                            </Fab>
    
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    flexDirection: "column",
                                    padding: 5,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "row",
                                    }}
                                >
                                </Box>
                                <Button
                                    variant="contained"
                                    onClick={() => setDialogOpen(true)}
                                >
                                    <IconButton
                                        aria-label="save"
                                        style={{ color: "white" }}
                                        onClick={() => {
                                            console.log("hola");
                                        }}
                                    >
                                        <FilterAltIcon />
                                    </IconButton>
                                </Button>
                                <Dialog
                                    open={dialogOpen}
                                    onClose={() => setDialogOpen(false)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();  // Prevenir la acción por defecto de la tecla Enter
                                            filterEntities();  // Llama a filterEntities en lugar de handleApplyFilters
                                            setDialogOpen(false);
                                        }
                                    }}
                                >
                                    <DialogTitle>Filtros</DialogTitle>
                                    <DialogContent
                                        sx={{
                                            paddingTop: 2,
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Buscar entidad"
                                            value={searchTerm}
                                            sx={{ marginBottom: 2, marginTop: 2 }}
                                            onChange={handleSearchChange}
                                        />
    
                                    </DialogContent>
                                    <DialogActions>
                                        <DialogActions>
                                            <Button
                                                variant="contained"
                                                onClick={resetFilters}
                                            >
                                                Restablecer Filtros
                                            </Button>
                                            <Button onClick={filterEntities}>
                                                Buscar
                                            </Button>
    
                                        </DialogActions>
                                    </DialogActions>
                                </Dialog>
                            </Box>
    
                            <DynamicTable
                                headers={headers}
                                rows={filteredEntities}  // Usa filteredEntities en lugar de entities
                                rowsPerPageOptions={rowsPerPageOptions}
                                onRowClick={handleDoctorRowClick}
                            />
                        </>
                    )}
                </>
            }
        />
    );
    
}
