import config from "../../config";

export const updateDataApi = async (data, id) => {
    console.log("----------------------", data);
    const token = localStorage.getItem('token');
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
        doctor_id: data.doctor_id,
        insurance_id: data.insurance,
        url: data.url,
        user: data.user,
        password: data.password,
    });

    let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response;
    response = await fetch(`${config.config.serverUrl}api/updateInsurance/${id}`, requestOptions);
    console.log(response);
    return response.json();
}