import { createSlice } from "@reduxjs/toolkit";


export const patientsSlice = createSlice({
    name: "patients",
    initialState: {
        patients: {},
        isLoading: true,
        isStart: false,
        doctors: [],
        hospitals: {},
        insurances: {},
        status: {},
        documents: {},
        isLoadingDoctors: true,
    },
    reducers: {
        setPatients: (state, action) => {
            state.patients = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsStart: (state, action) => {
            state.isStart = action.payload;
        },
        setDoctors: (state, action) => {
            state.doctors = action.payload;
        },
        setHospitals: (state, action) => {
            state.hospitals = action.payload;
        },
        setInsurances: (state, action) => {
            state.insurances = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setIsLoadingDoctors: (state, action) => {
            state.isLoadingDoctors = action.payload;
        },
        setDocuments: (state, action) => {
            console.log("action.payload", state, action.payload);
            state.documents = action.payload;
        }
    },
});

export const {
    setPatients,
    setIsLoading,
    setIsStart,
    setDoctors,
    setHospitals,
    setInsurances,
    setStatus,
    setIsLoadingDoctors,
    setDocuments
} = patientsSlice.actions;
export default patientsSlice.reducer;
