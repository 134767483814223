import config from '../../config';

export const createEntityContactApi = async (entityData, id) => {
    console.log('createEntityContactApi', entityData, id);

    const token = localStorage.getItem('token');

    const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        Cookie: `XSRF-TOKEN=${token}`,
        'Content-Type': 'application/json',
    });

    const data = {
        entidad: id,
        nombre: entityData.nombre,
        puesto: entityData.puesto,
        telefono: entityData.telefono,
        email: entityData.email,
        estatus: "1"
    };

    console.log('data', data);

    // Set up the request options
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    // Construct the URL from the config
    const url = `${config.config.serverUrl}api/storecontact`;
    console.log('url', url);

    try {
        // Send the request and await the response
        const response = await fetch(url, requestOptions);

        console.log('response', response);

        return response.json();
    } catch (error) {
        console.log('error', error);
    }
}
