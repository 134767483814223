import { useEffect, useState } from "react";
import { getPatientsWithoutUser } from "../api/patient/getPatientsWithoutUser";
import { useSelector } from "react-redux";
import Home from "./Home";
import TableCards from "../components/tables/TableCards";
import { Grid, Skeleton } from '@mui/material';

const Dashboard = () => {
    const { user, userInfo } = useSelector((state) => state.user);
    const [patients, setPatients] = useState(null);

    useEffect(() => {
        const fetchPatientData = async () => {
            console.log("fetchPatientData", JSON.stringify(userInfo.name));
            if (userInfo.role_id === 1 || userInfo.role_id === 2) {
                let patientData = await getPatientsWithoutUser();
                patientData = JSON.parse(patientData);
                setPatients(patientData.patients);
            }
        };

        fetchPatientData();
    }, []);

    const skeletonCard = (
        <Grid item xs={12} sm={6} md={4}>
            <Skeleton variant="rectangular" height={250} />
        </Grid>
    );

    return (
        <div>
            <Home
                children={
                    <>
                        <h1>Bienvenido {userInfo.name}</h1>
                        {userInfo.role_id === 1 || userInfo.role_id === 2 ? (
                            patients && patients.data ? (
                                <div>
                                    <TableCards data={patients.data} />
                                </div>
                            ) : (
                                <div>
                                    <Grid container spacing={2}>
                                        {skeletonCard}
                                        {skeletonCard}
                                        {skeletonCard}
                                        {skeletonCard}
                                        {skeletonCard}
                                        {skeletonCard}
                                        {skeletonCard}
                                        {skeletonCard}
                                        {skeletonCard}
                                    </Grid>
                                </div>
                            )
                        ) : (
                            <div>
                                <h1>Usted no tiene permisos para ver esta página</h1>
                            </div>
                        )
                        }
                        {/* {patients && patients.data ? (
                            <div>
                                <TableCards data={patients.data} />
                            </div>
                        ) : (
                            <div>
                                <Grid container spacing={2}>
                                    {skeletonCard}
                                    {skeletonCard}
                                    {skeletonCard}
                                    {skeletonCard}
                                    {skeletonCard}
                                    {skeletonCard}
                                    {skeletonCard}
                                    {skeletonCard}
                                    {skeletonCard}
                                </Grid>
                            </div>
                        )} */}
                    </>
                }
                title={"Panel de inicio"}
            />
        </div>
    );
};

export default Dashboard;
