import {configureStore} from '@reduxjs/toolkit'
import thunk from "redux-thunk";
import userReducer from './slice/userSlice';
import {patientsSlice} from "./slice/patientsSlice";
import {pageSlice} from "./slice/pageSlice";
import {doctorSlice} from "./slice/doctorSlice";


export default configureStore({
    applyMiddleware: [thunk],
    reducer: {
        user: userReducer,
        patients: patientsSlice.reducer,
        page: pageSlice.reducer,
        doctors: doctorSlice.reducer,
    },
})

