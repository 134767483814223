import config from "../../config";

export const getSpecialtiesApi = async () => {
    console.log('getSpecialtiesApi');
    const token = localStorage.getItem('token');
    const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        Cookie: `XSRF-TOKEN=${token}`,
        'Content-Type': 'application/json',
    });
    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    const response = await fetch(`${config.config.serverUrl}api/specialty`, requestOptions)
    return response.json();
}
