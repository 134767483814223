import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Skeleton, TextField } from '@mui/material';
import Home from './Home';
import DynamicTable from '../components/tables/DynamicTable';
import { doctorsApi } from '../api/doctors/doctorsApi';
import { setDoctors, setIsLoading } from '../slice/doctorSlice';
import useWindowDimensions from "../functions/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { SelectController } from '../atoms/AtomsForms';

const Doctor = () => {
    const { isLoading, doctors } = useSelector((state) => state.doctors);
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [filterChips, setFilterChips] = useState([]);
    const [tempFilter, setTempFilter] = useState({});
    const { height, width } = useWindowDimensions();
    const [searchTermDoctor, setSearchTermDoctor] = useState('');
    const [filteredDoctors, setFilteredDoctors] = useState([]);
    const [searchTermSpecialty, setSearchTermSpecialty] = useState('');
    const navigate = useNavigate();
    const headers = ['id', 'Médico', 'Especialidad', 'Email', 'RFC', 'Estatus'];
    const rowsPerPageOptions = [20, 50, 100];

    const extractDoctorInfo = (doctor) => [
        doctor.id,
        doctor.business_name,
        doctor.specialty_name,
        doctor.email,
        doctor.rfc,
        doctor.status === 1 ? 'Activo' : 'Inactivo',
    ];

    useEffect(() => {
        const fetchDoctors = async () => {
            const doctorsData = await doctorsApi();
            console.log('doctorsData', doctorsData);
            const doctorInfoArray = doctorsData.doctors.map(extractDoctorInfo);
            dispatch(setDoctors(doctorInfoArray));
            setFilteredDoctors(doctorInfoArray);  // Establece los doctores filtrados a los doctores iniciales
            dispatch(setIsLoading(false));
        };

        fetchDoctors();
    }, [dispatch]);


    const handleDoctorRowClick = (doctorInfo) => {
        console.log('Selected doctor:', doctorInfo);
        window.location.href = '/doctor?id=' + doctorInfo[0];
        //navigate(`/doctor?id=${doctorInfo[0]}`)
    };

    const filterDoctors = () => {
        const filtered = doctors.filter(doctor =>
            doctor &&
            (searchTermDoctor === '' || (doctor[1] && doctor[1].toLowerCase().includes(searchTermDoctor.toLowerCase()))) &&
            (searchTermSpecialty === '' || (doctor[2] && doctor[2].toLowerCase().includes(searchTermSpecialty.toLowerCase())))
        );
        setFilteredDoctors(filtered);
    };


    const handleApplyFilters = () => {
        filterDoctors();
        setDialogOpen(false);
    };


    const handleRemoveChip = (chipKey) => {
        // setFilterChips(filterChips.filter((chip) => chip.key !== chipKey));
        // setFilter({ ...filter, [chipKey]: "" });
    };


    const handleFilterChange = (key) => (e) => {
        setTempFilter({ ...tempFilter, [key]: e.target.value });
    };

    const resetFilters = () => {
        setSearchTermDoctor('');
        setSearchTermSpecialty('');
        setFilteredDoctors(doctors);  // Restablece los doctores filtrados a los doctores originales
    };

    const labelMap = {
        doctor: "Médico",
        specialty: "Especialidad",
    };

    return (
        <Home
            title="Médicos"
            children={
                isLoading ? (
                    <Skeleton variant="rectangular" width={width / 1.1} height={height / 1.2} />
                ) : (
                    <Box>
                        <Fab color="primary" aria-label="add" style={{
                            margin: 0,
                            top: 'auto',
                            right: 20,
                            bottom: 20,
                            left: 'auto',
                            position: 'fixed',
                        }}>
                            <IconButton aria-label="save" style={{ color: "white" }} onClick={() => {
                                navigate('/createDoctor')
                            }}>
                                <AddIcon />
                            </IconButton>
                        </Fab>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 3,
                            }}
                        >
                            {filterChips.map((chip) => (
                                <Box key={chip.key}>
                                    <Chip
                                        label={`${labelMap[chip.key]}: ${chip.label}`}
                                        onDelete={() => handleRemoveChip(chip.key)}
                                        variant="outlined"
                                    />
                                    <Box sx={{ width: 1 }} />
                                </Box>
                            ))}
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    flexDirection: "column",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "row",
                                    }}
                                >
                                </Box>
                                <Button
                                    variant="contained"
                                    onClick={() => setDialogOpen(true)}
                                >
                                    <IconButton
                                        aria-label="save"
                                        style={{ color: "white" }}
                                        onClick={() => {
                                            console.log("hola");
                                        }}
                                    >
                                        <FilterAltIcon />
                                    </IconButton>
                                </Button>
                                <Dialog
                                    open={dialogOpen}
                                    onClose={() => setDialogOpen(false)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();  // Prevenir la acción por defecto de la tecla Enter
                                            handleApplyFilters();
                                        }
                                    }}
                                >
                                    <DialogTitle>Filtros</DialogTitle>
                                    <DialogContent
                                        sx={{
                                            paddingTop: 2,
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Buscar médico"
                                            value={tempFilter.doctor}
                                            sx={{ marginBottom: 2, marginTop: 2 }}
                                            onChange={(e) => setSearchTermDoctor(e.target.value)}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Buscar especialidad"
                                            value={tempFilter.specialty}
                                            sx={{ marginBottom: 2, marginTop: 2 }}
                                            onChange={(e) => setSearchTermSpecialty(e.target.value)}
                                        />

                                    </DialogContent>
                                    <DialogActions>
                                        <DialogActions>
                                            <Button
                                                variant="contained"
                                                onClick={resetFilters}
                                            >
                                                Restablecer Filtros
                                            </Button>
                                            <Button onClick={handleApplyFilters}>
                                                Buscar
                                            </Button>

                                        </DialogActions>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Box>
                        <DynamicTable
                            headers={headers}
                            rows={filteredDoctors}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onRowClick={handleDoctorRowClick}
                        />
                    </Box>
                )
            }
        />
    );
};

export default Doctor;
