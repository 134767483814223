//Config app
const configApp = {
  config: {
    //Url of the server
    //serverUrl: "http://127.0.0.1:8000/",
    serverUrl: "https://back.administramedic.com/",
  },
  styles: {
    principalColor: "#A2C6F6",
  },
};
export default configApp;
