export default function formatMoney(amount) {
    const options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    };
  
    // Check if it's a clean dollar amount
    if (Number.isInteger(amount)) {
      options.minimumFractionDigits = 0;
    }
  
    const formatter = Intl.NumberFormat("en-US", options);
  
    return formatter.format(amount);
  }
  