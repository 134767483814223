import config from "../../config";
import configApp from "../../config";

export const getUsersApi = async () => {
    const token = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            Cookie: `XSRF-TOKEN=${token}`,
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
    };
    try {
        const response = await fetch(`${config.config.serverUrl}api/getUsers`, requestOptions)
            .then(response => response.json());
        return JSON.stringify(response);
    } catch (error) {
        console.log("error", error);
        return null;
    }
}
