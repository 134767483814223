import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  TextField,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import DynamicTableReport from "../../components/tables/DynamicTableReport";
import Home from "../Home";
import React, { useState, useEffect } from "react";
import { getPorTabularApi } from "../../api/reportes/porTabularApi";
import { SelectController } from "../../atoms/AtomsForms";

export const PorTabular = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    patient: "",
    doctor: "",
    insurance: "",
    hospital: "",
  });
  const [update, setUpdate] = useState(false);

  const rowsPerPageOptions = [20, 50, 100];
  const [patients, setPatients] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1, // Inicia en la página 1
    first_page_url: "",
    last_page: "",
    last_page_url: "",
    next_page_url: "",
    path: "",
    per_page: rowsPerPageOptions[0], // Utiliza el primer valor en tus opciones
    prev_page_url: "",
    to: "",
    total: "",
  });
  const headers = [
    "id",
    "#",
    "Médico",
    "Paciente",
    "Consecutivo",
    "Fecha de registro",
    "Fecha de atención",
    "Aseguradora",
    "Hospital",
    "Procedimientos Realizados",
  ];
  const n = 20;
  const m = 3;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);


  const [dialogOpen, setDialogOpen] = useState(false);
  const [tempFilter, setTempFilter] = useState(filter);

  const applyFilters = () => {
    setFilter(tempFilter);
    setDialogOpen(false);
    setUpdate(!update);
  };

  const resetFilters = () => {
    const emptyFilter = {
      patient: "",
      doctor: "",
      insurance: "",
      hospital: "",
    };
    setTempFilter(emptyFilter);
    setFilter(emptyFilter);
    setDialogOpen(false);
    setUpdate(!update);
  };

  const cellStyle = {
    padding: "10px",
    backgroundColor: "transparent",
    position: "relative",
  };

  const skeletonStyle = {
    backgroundColor: "#f5f5f5",
    animationDuration: "2s",
  };

  const extractPatientInfo = (patient) => {
    const createdAt = new Date(patient.created_at);
    const day = String(createdAt.getDate()).padStart(2, "0");
    const month = String(createdAt.getMonth() + 1).padStart(2, "0");
    const year = createdAt.getFullYear();
    const formattedCreatedAt = `${day}/${month}/${year}`;

    return [
      patient.id,
      patient.doctor_name,
      patient.patient,
      patient.order,
      formattedCreatedAt,
      patient.date_attention,
      patient.aseguradora_name,
      patient.hospital_name,
      patient.doctor_observation,
    ];
  };

  const fetchData = async (page, rowsPerPage) => {
    setIsLoading(true);
    let paginatedData = await getPorTabularApi(filter, page, rowsPerPage);
    paginatedData = JSON.parse(paginatedData);
    paginatedData = paginatedData.patients;
    console.log("paginatedData1", paginatedData);
    let {
      data,
      current_page,
      first_page_url,
      last_page,
      last_page_url,
      next_page_url,
      path,
      per_page,
      prev_page_url,
      to,
      total,
    } = paginatedData;

    setPagination({
      current_page,
      first_page_url,
      last_page,
      last_page_url,
      next_page_url,
      path,
      per_page,
      prev_page_url,
      to,
      total,
    });
    console.log("paginatedData2", data);
    if (data) {
      console.log("data", data);
      const patientInfo = data.map(extractPatientInfo);
      console.log("patientInfo", patientInfo);
      setPatients(patientInfo);
      console.log("patients", patients);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage, update]);

  const handlePatientClick = (patientsInfo) => {
    console.log("patientsInfo", patientsInfo);
  };

  const handleRowsPerPageChange = (event) => {
    console.log("rowsPerPage", event.target.value);
    setRowsPerPage(event.target.value);
  };

  const handlePageChange = (event, page, rowsPerPage) => {
    console.log("page", page);
    setPage(page);
    setRowsPerPage(rowsPerPage);
  };

  return (
    <Home
      title="Reporte por Tabular"
      children={
        <>
          {isLoading ? (
            <TableContainer>
              <Table>
                <TableBody>
                  {[...Array(n)].map((_, i) => (
                    <TableRow key={i}>
                      {[...Array(m)].map((_, j) => (
                        <TableCell key={j} sx={cellStyle}>
                          <Skeleton
                            variant="rectangular"
                            width={200}
                            height={30}
                            sx={skeletonStyle}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 2,
                  flexDirection: "row",
                  display: "flex",
                  padding: 3,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => setDialogOpen(true)}
                >
                  Filtrar
                </Button>
                <Dialog
                  open={dialogOpen}
                  sx={{ padding: 3 }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      applyFilters();
                    }
                  }}
                  onClose={() => setDialogOpen(false)}
                >
                  <DialogTitle>Filtros</DialogTitle>
                  <DialogContent>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Buscar médico"
                      value={tempFilter.doctor}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      onChange={(e) =>
                        setTempFilter({ ...tempFilter, doctor: e.target.value })
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Buscar paciente"
                      value={tempFilter.patient}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      onChange={(e) =>
                        setTempFilter({ ...tempFilter, patient: e.target.value })
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Buscar hospital"
                      value={tempFilter.hospital}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      onChange={(e) =>
                        setTempFilter({ ...tempFilter, hospital: e.target.value })
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Buscar aseguradora"
                      value={tempFilter.insurance}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      onChange={(e) =>
                        setTempFilter({ ...tempFilter, insurance: e.target.value })
                      }
                    />
                     <SelectController
                      label="Fechas"
                      options={[
                        { id: '0', label: 'Fecha inicio' },
                        { id: '1', label: 'Fecha fin' },
                      ]}
                      onChange={(event, newValue) => {
                        setTempFilter({ ...tempFilter, orderBy: newValue.id });
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={resetFilters} variant="contained">
                      Restablecer
                    </Button>
                    <Button onClick={applyFilters} variant="contained">
                      Buscar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
              <DynamicTableReport
                headers={headers}
                rows={patients}
                pagination={pagination}
                rowsPerPageOptions={rowsPerPageOptions}
                rowsPerPage={rowsPerPage}
                onRowClick={handlePatientClick}
                onPageChange={handlePageChange}
                type={47}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
              />
            </>
          )}
        </>
      }
    />
  );
};
