import Home from "./Home";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getDoctorApi } from "../api/doctors/getDoctorApi";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { InputController, SelectController } from "../atoms/AtomsForms";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import { updateDoctorApi } from "../api/doctors/updateDoctorApi";
import { FileModal } from "../components/FileComponentDoctor";
import PasswordsDoctors from "../atoms/PasswordsDoctors";

export const ShowDoctor = () => {
  const { userInfo } = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [doctorData, setDoctorData] = React.useState({});
  const [specialties, setSpecialties] = React.useState([]);
  const [paymentAgreements, setPaymentAgreements] = React.useState([]);
  const [doctor_files, setDoctorFiles] = React.useState([]);
  const [passwords_insurances, setPasswordsInsurances] = React.useState([]);
  const [insurances, setInsurances] = React.useState([]);

  const types_business_options = [
    { id: 36, label: "Física" },
    { id: 37, label: "Moral" },
  ];

  const getData = async () => {
    console.log("getData", id);
    let response = await getDoctorApi(id);
    response = JSON.parse(response);
    console.log("response", response);
    setDoctorData(response.doctor);
    let files = response.files.map((file) => ({
      description: file.description,
      file: file.file,
      status: file.status,
    }));
    setDoctorFiles(files);
    let passwordsInsurances = response.userAndPassword;
    console.log("passwordsInsurances", passwordsInsurances);
    setPasswordsInsurances(passwordsInsurances);
    const formattedSpecialties = response.specialties.map((specialty) => ({
      id: specialty.id,
      label: specialty.name,
    }));
    setSpecialties(formattedSpecialties);

    const formattedInsurances = response.insurances.map((insurance) => ({
      id: insurance.id,
      label: insurance.name,
    }));
    setInsurances(formattedInsurances);

    const formattedPaymentAgreements = response.payments.map(
      (payment_agreement) => ({
        id: payment_agreement.id,
        label: payment_agreement.description,
      })
    );
    setPaymentAgreements(formattedPaymentAgreements);
  };

  useEffect(() => {
    const doctor = async () => {
      getData();
    };
    doctor();
  }, [userInfo]);

  const updateDoctor = async () => {
    console.log("updateDoctor", doctorData);
    const response = await updateDoctorApi(id, doctorData);
    if (!response.error) {
      alert("Médico actualizado correctamente");
      window.location.reload();
    } else {
      alert("Hubo un error al actualizar el médico");
    }
    console.log("responseUpdateDoctor", response);
  };

  return (
    <Home
      title="Médico"
      children={
        <div>
          <Fab
            color="primary"
            aria-label="add"
            style={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
          >
            <IconButton
              aria-label="save"
              style={{ color: "white" }}
              onClick={async () => {
                await updateDoctor();
              }}
            >
              <SaveIcon />
            </IconButton>
          </Fab>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">
                        {"Médico: " + doctorData?.business_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        align="right"
                        sx={{ display: { xs: "none", sm: "block" } }}
                      >
                        {"Fecha de registro: " +
                          dayjs(doctorData?.created_at).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        align="left"
                        sx={{ display: { xs: "block", sm: "none" } }}
                      >
                        {"Fecha de registro: " +
                          dayjs(doctorData?.created_at).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} alignItems="center">
                    <InputController
                      label="Nombre"
                      value={doctorData?.name}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          name: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Apellido Paterno"
                      value={doctorData?.lastname}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          lastname: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Apellido Materno"
                      value={doctorData?.secondlastname}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          secondlastname: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Cedula profesional, esp. o sub."
                      value={doctorData?.professional_business}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          professional_business: e.target.value,
                        })
                      }
                    />
                    <SelectController
                      label="Especialidad"
                      options={specialties}
                      value={
                        specialties.find(
                          (specialty) =>
                            specialty.label === doctorData?.specialty_name
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDoctorData({
                            ...doctorData,
                            specialty_id: newValue.id,
                            specialty_name: newValue.label,
                          });
                        }
                      }}
                    />
                    <SelectController
                      label="Acuerdo de pago"
                      options={paymentAgreements}
                      value={
                        paymentAgreements.find(
                          (agreement) => agreement.id === doctorData?.agreement
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDoctorData({
                            ...doctorData,
                            agreement: newValue.id,
                            agreement_description: newValue.label,
                          });
                        }
                      }}
                    />
                     <SelectController
                      label="Estatus"
                      options={[
                        { id: 1, label: "Activo" },
                        { id: 0, label: "Inactivo" },
                      ]}
                      value={
                        doctorData.status === 1
                          ? { id: 1, label: "Activo" }
                          : { id: 0, label: "Inactivo" }
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDoctorData({
                            ...doctorData,
                            status: newValue.id,
                          });
                        }
                      }}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">{"Datos"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} alignItems="center">
                    <InputController
                      label="Correo electrónico"
                      value={doctorData?.email}
                    />
                    <InputController
                      label="Celular"
                      value={doctorData?.mobile}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          mobile: e.target.value,
                        })
                      }
                    />
                    <SelectController
                      label="Tipo de Persona"
                      options={types_business_options}
                      value={
                        types_business_options.find(
                          (option) => option.id === doctorData?.types_business
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDoctorData({
                            ...doctorData,
                            types_business: newValue.id,
                          });
                        }
                      }}
                    />
                    <InputController
                      label="RFC"
                      value={doctorData?.rfc}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          rfc: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Razón social"
                      value={doctorData?.business_name}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          business_name: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Dirección fiscal"
                      value={doctorData?.address}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          address: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Código postal"
                      value={doctorData?.zip}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          zip: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="CURP"
                      value={doctorData?.curp}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          curp: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">{"Cuenta"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} alignItems="center">
                    <InputController
                      label="Número de cuenta"
                      value={doctorData?.account_payment}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          account_payment: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Banco"
                      value={doctorData?.bank}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          bank: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Clabe"
                      value={doctorData?.clabe}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          clabe: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Tipo de cuenta"
                      value={doctorData?.type_account}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          type_account: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Observaciones"
                      value={doctorData?.observations}
                      rows={3}
                      fullWidth={true}
                      multiline={true}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          observations: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">{"Consultorio"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} alignItems="center">
                    <InputController
                      label="Lugar del consultorio"
                      value={doctorData?.name_hospital}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          name_hospital: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Calle"
                      value={doctorData?.street}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          street: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Número exterior"
                      value={doctorData?.number}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          number: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Número interior"
                      value={doctorData?.internal_number}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          internal_number: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Colonia"
                      value={doctorData?.suburb_hospital}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          suburb_hospital: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Municipio"
                      value={doctorData?.town_hospital}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          town_hospital: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Código postal"
                      value={doctorData?.zip_hospital}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          zip_hospital: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Télefono"
                      value={doctorData?.phone}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          phone: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Nombre Secretaria"
                      value={doctorData?.secretary}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          secretary: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Correo Secretaria"
                      value={doctorData?.email_secretary}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          email_secretary: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">{"Facturación"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} alignItems="center">
                    <SelectController
                      label="Médico emite sus facturas"
                      options={[
                        { id: 1, label: "Sí" },
                        {
                          id: 0,
                          label: "No",
                        },
                      ]}
                      value={
                        doctorData.invoice === 1
                          ? { id: 1, label: "Sí" }
                          : {
                            id: 0,
                            label: "No",
                          }
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDoctorData({
                            ...doctorData,
                            invoice: newValue.id,
                          });
                        }
                      }}
                    />
                    <InputController
                      label="Liga de facturación"
                      value={doctorData?.sat_link}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          sat_link: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Usuario de facturación"
                      value={doctorData?.sat_user}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          sat_user: e.target.value,
                        })
                      }
                    />
                    {/* //TODO: Change to password input and refactor*/}
                    <InputController
                      label="Clave de facturación"
                      value={doctorData?.sat_key}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          sat_key: e.target.value,
                        })
                      }
                    />
                    <InputController
                      label="Observaciones"
                      value={doctorData?.schedule}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          schedule: e.target.value,
                        })
                      }
                    />
                    <SelectController
                      label="Retención de ISR"
                      options={[
                        { id: 1, label: "Sí" },
                        {
                          id: 0,
                          label: "No",
                        },
                      ]}
                      value={
                        doctorData.isr === 1
                          ? { id: 1, label: "Sí" }
                          : {
                            id: 0,
                            label: "No",
                          }
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDoctorData({
                            ...doctorData,
                            isr: newValue.id,
                          });
                        }
                      }}
                    />
                    <InputController
                      label="ISR"
                      value={doctorData?.por_isr}
                      onChange={(e) =>
                        setDoctorData({
                          ...doctorData,
                          por_isr: e.target.value,
                        })
                      }
                    />
                    <SelectController
                      label="Habilitar impuesto local"
                      options={[
                        { id: 1, label: "Sí" },
                        { id: 0, label: "No" },
                      ]}
                      value={
                        doctorData.imp_local === 1
                          ? { id: 1, label: "Sí" }
                          : { id: 0, label: "No" }
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDoctorData({
                            ...doctorData,
                            imp_local: newValue.id,
                          });
                        }
                      }}
                    />
                    {
                      doctorData.imp_local === 1 || doctorData.imp_local === "1" ? (
                        <InputController
                          label="Porcentaje de impuesto local"
                          value={doctorData?.imp_localIV}
                          onChange={(e) =>
                            setDoctorData({
                              ...doctorData,
                              imp_localIV: e.target.value,
                            })
                          }
                        />
                      )
                        : null
                    }
                    <SelectController
                      label="Tipo de régimen"
                      options={[
                        { id: 1, label: "Régimen Simplificado de Confianza" },
                        {
                          id: 0,
                          label:
                            "Régimen de personas fisicas con actividad empresarial",
                        },
                      ]}
                      value={
                        doctorData.typeRegimen === 1
                          ? { id: 1, label: "Régimen Simplificado de Confianza" }
                          : {
                            id: 0,
                            label:
                              "Régimen de personas fisicas con actividad empresarial",
                          }
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setDoctorData({
                            ...doctorData,
                            typeRegimen: newValue.id,
                          });
                        }
                      }}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">{"Archivos"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <FileModal doctor={doctorData} doctor_files={doctor_files} />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: '100px' }}>
              <Accordion defaultExpanded={false} >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">
                        {"Claves Aseguradoras"}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <PasswordsDoctors data={passwords_insurances} doctorData={doctorData} insurances={insurances} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
};
