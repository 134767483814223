import {createSlice} from "@reduxjs/toolkit";


export const pageSlice = createSlice({
    name: "page",
    initialState: {
        actualPage: "Dashboard",
    },
    reducers: {
        setActualPage: (state, action) => {
            state.actualPage = action.payload;
        }
    },
});
export const selectPage = (state) => state.actualPage;
export const {setActualPage} = pageSlice.actions;
export default pageSlice.reducer;
