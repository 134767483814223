import {
  Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Box, TextField, Button, Fab, IconButton, Chip, Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import Home from "./Home";
import React, { useState, useEffect } from "react";
import formatMoney from "../functions/formatMoney";
import DynamicTablePatients from "../components/tables/PatientTable";
import { patientApi } from "../api/patient/patientApi";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import { SelectController } from "../atoms/AtomsForms";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0000ff",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0000ff",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000088",
    },
    "& .MuiOutlinedInput-input": {
      color: "#000000", // Color del texto
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#0000ff", // Color del texto cuando el mouse está encima
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#000088", // Color del texto cuando está seleccionado
    },
    "& .MuiInputLabel-outlined": {
      color: "#000000", // Color del label
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#000000", // Color del label cuando el mouse está encima
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#000088", // Color del label cuando está seleccionado
    },
  },
});

export const Pacientes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.user);
  const [amounts, setAmounts] = useState({});
  const navigate = useNavigate();
  const classes = useStyles();
  const [filter, setFilter] = useState({ patient: "", doctor: "", insurance: "", hospital: "", receipt: "", consecutive: "", status: "" });
  const [update, setUpdate] = useState(false);
  const rowsPerPageOptions = [20, 50, 100];
  const [patients, setPatients] = useState([]);
  const [tempFilter, setTempFilter] = useState({});
  const [filterChips, setFilterChips] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pagination, setPagination] = useState({ current_page: 1, first_page_url: "", last_page: "", last_page_url: "", next_page_url: "", path: "", per_page: rowsPerPageOptions[0], prev_page_url: "", to: "", total: "" });

  const n = 20;
  const m = 3;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const headers = ["id", "Médico", "Paciente", "Consecutivo", "Recibo", "Fecha de atención", "Aseguradora", "Hospital", "Estatus", "Fecha de pago", "Subtotal"];

  const cellStyle = {
    padding: "10px",
    backgroundColor: "transparent",
    position: "relative",
  };

  const skeletonStyle = {
    backgroundColor: "#f5f5f5",
    animationDuration: "2s",
  };

  //If userInfo.role_id === 1, add "Comisión" to patientInfo

  const extractPatientInfo = (patient) => {
    console.log("patient14", patient);
    const data = [patient.id, patient.doctor_name, patient.patient, patient.order, patient.receipt, patient.date_attention, patient.insurance_name, patient.hospital.name, patient.status.description, patient.payment_date, formatMoney(patient.subtotal)];
    return data;
  };

  // const extractPatientInfo = (patient) => {
  //   return [patient.id, patient.doctor_name, patient.patient, patient.order, patient.receipt, patient.date_attention, patient.insurance_name, patient.status.description, patient.payment_date, formatMoney(patient.subtotal), formatMoney(patient.isr), formatMoney(patient.total), formatMoney(patient.commission),
  //   ];
  // };

  const fetchData = async (page, rowsPerPage) => {
    setIsLoading(true);
    let paginatedData = await patientApi(filter, page, rowsPerPage);
    paginatedData = JSON.parse(paginatedData);
    console.log("paginatedData", paginatedData);
    let amountFormated = paginatedData.amounts;
    //amountFormated = formatMoney(amountFormated);
    for (var key in amountFormated) {
      amountFormated[key] = formatMoney(amountFormated[key]);
    }
    //console.log("amountFormated", amountFormated);
    setAmounts(amountFormated);
    //console.log("amounts", amounts);
    paginatedData = paginatedData.patients;
    //console.log("paginatedData1", paginatedData);
    let { data, current_page, first_page_url, last_page , last_page_url,
      next_page_url, path, per_page, prev_page_url, to, total, } = paginatedData;

    setPagination({
      current_page,
      first_page_url,
      last_page,
      last_page_url,
      next_page_url,
      path,
      per_page,
      prev_page_url,
      to,
      total,
    });
    // console.log("paginatedData2", data);
    if (data) {
      //console.log("data", data);
      const patientInfo = data.map(extractPatientInfo);
      //console.log("patientInfo", patientInfo);
      setPatients(patientInfo);
      //console.log("patients", patients);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getPatients = async () => {
      await fetchData(page, rowsPerPage);
    };
    getPatients();
    //fetchData(page, rowsPerPage);
  }, [page, rowsPerPage, update]);

  const handlePatientClick = (patientsInfo) => {
    // console.log("patientsInfo", patientsInfo);
  };

  const handleRowsPerPageChange = (event) => {
    console.log("rowsPerPage", event.target.value);
    setRowsPerPage(event.target.value);
  };

  const handlePageChange = (event, page, rowsPerPage) => {
    console.log("page", page);
    setPage(page);
    setRowsPerPage(rowsPerPage);
  };

  const handleApplyFilters = () => {
    const newFilter = {};
    for (var key in tempFilter) {
      if (tempFilter[key] !== "") {
        newFilter[key] = tempFilter[key];
      }
    }

    setFilter(newFilter);

    const newChips = Object.keys(newFilter).map((key) => ({
      key,
      label: newFilter[key],
    }));

    setFilterChips(newChips);

    setDialogOpen(false);

    setUpdate(!update);
  };


  const handleFilterChange = (key) => (e) => {
    setTempFilter({ ...tempFilter, [key]: e.target.value });
  };

  const handleRemoveChip = (chipKey) => {
    setFilterChips(filterChips.filter((chip) => chip.key !== chipKey));
    setFilter({ ...filter, [chipKey]: "" });
  };

  const labelMap = {
    patient: "Paciente",
    doctor: "Médico",
    hospital: "Hospital",
    insurance: "Aseguradora",
    receipt: "Recibo",
    consecutive: "Consecutivo",
    status: "Estatus",
  };

  return (
    <div>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
          color: "white",
        }}
      >
        <IconButton
          aria-label="save"
          sx={{ color: "white" }}
          size="large"
          onClick={() => {
            //navigate("/createPatient");
            window.location.href = '/createPatient';
          }}
        >
          <AddIcon />
        </IconButton>
      </Fab>
      <Home
        title="Registro de pacientes"
        children={
          <>
            {isLoading ? (
              <TableContainer>
                <Table>
                  <TableBody>
                    {[...Array(n)].map((_, i) => (
                      <TableRow key={i}>
                        {[...Array(m)].map((_, j) => (
                          <TableCell key={j} sx={cellStyle}>
                            <Skeleton
                              variant="rectangular"
                              width={200}
                              height={30}
                              sx={skeletonStyle}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 3,
                  }}
                >
                  {/* {filterChips.map((chip) => (
                    <Box key={chip.key}>
                      <Chip
                        label={`${labelMap[chip.key]}: ${chip.label}`}
                        onDelete={() => handleRemoveChip(chip.key)}
                        variant="outlined"
                      />
                      <Box sx={{ width: 1 }} />
                    </Box>
                  ))} */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    ></Box>
                    
                    <Button
                      variant="contained"
                      onClick={() => setDialogOpen(true)}
                    >
                      <IconButton
                        aria-label="save"
                        style={{ color: "white" }}
                        onClick={() => {
                          console.log("hola");
                        }}
                      >
                        <FilterAltIcon />
                      </IconButton>
                    </Button>
                    <Dialog
                      open={dialogOpen}
                      onClose={() => setDialogOpen(false)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();  // Prevenir la acción por defecto de la tecla Enter
                          handleApplyFilters();
                        }
                      }}
                    >
                      <DialogTitle>Filtros</DialogTitle>
                      <DialogContent
                        sx={{
                          paddingTop: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Buscar paciente"
                          value={tempFilter.patient || ""}
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          onChange={handleFilterChange("patient")}
                        />
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Buscar médico"
                          value={tempFilter.doctor}
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          onChange={handleFilterChange("doctor")}
                        />
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Buscar hospital"
                          value={tempFilter.hospital}
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          onChange={handleFilterChange("hospital")}
                        />
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Buscar aseguradora"
                          value={tempFilter.insurance}
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          onChange={handleFilterChange("insurance")}
                        />

                        <Box
                          sx={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 1,
                          }}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Recibo"
                            value={tempFilter.receipt}
                            sx={{
                              marginBottom: 2,
                              marginTop: 2,
                              marginLeft: 1.5,
                              marginRight: 1.5,
                            }}
                            onChange={handleFilterChange("receipt")}
                          />
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Buscar consecutivo"
                            value={tempFilter.consecutive}
                            sx={{
                              marginBottom: 2,
                              marginTop: 2,
                              marginLeft: 1.5,
                              marginRight: 1.5,
                            }}
                            onChange={handleFilterChange("consecutive")}
                          />
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Buscar estatus"
                            value={tempFilter.status}
                            sx={{
                              marginBottom: 2,
                              marginTop: 2,
                              marginLeft: 1.5,
                              marginRight: 1.5,
                            }}
                            onChange={handleFilterChange("status")}
                          />
                          <SelectController
                            label="Fechas"
                            options={[
                              { id: 'fecha_inicio', label: 'Fecha inicio' },
                              { id: 'fecha_fin', label: 'Fecha fin' },
                            ]}
                            onChange={(event, newValue) => {
                              console.log("newValue", newValue);
                            }}
                          />

                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <DialogActions>
                          <Button onClick={handleApplyFilters}>
                            Buscar
                          </Button>
                        </DialogActions>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Box>
                <DynamicTablePatients
                  headers={headers}
                  rows={patients}
                  pagination={pagination}
                  rowsPerPageOptions={rowsPerPageOptions}
                  rowsPerPage={rowsPerPage}
                  onRowClick={handlePatientClick}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  amounts={amounts}
                  page={page}
                />
              </>
            )}
          </>
        }
      />
    </div>
  );
};
