import config from "../../config";

export const changeToPagado = async (patients) => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers({
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json", // Necesario para enviar el cuerpo JSON
    });
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ patients }), // Enviaremos la lista de pacientes como cuerpo de la solicitud
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${config.config.serverUrl}api/to-pagado`, requestOptions)
            .then(response => response.json());
        return JSON.stringify(response);
    } catch (error) {
        console.log("error", error);
        return null;
    }
}
