import React, {useState} from "react";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {Form, FormikProvider, useFormik} from "formik";
import {Navigate} from "react-router-dom";

import * as Yup from "yup";

import {
    Alert,
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    TextField,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Icon} from "@iconify/react";
import {motion} from "framer-motion";
import {loginApi} from "../api/auth/loginApi";
import authService from "../auth/authService";
import {useDispatch, useSelector} from "react-redux";
import {setUser, setUserInfo} from "../slice/userSlice";
import {userApi} from "../api/auth/userApi";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
    opacity: 1,
    y: 0,
    transition: {
        duration: 0.6,
        ease: easing,
        delay: 0.16,
    },
};

const LoginForm = ({setAuth}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const {userInfo, user} = useSelector(state => state.user);

    const refresh = () => window.location.reload(false);


    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Proporcione un correo válido")
            .required("El correo es requerido"),
        password: Yup.string().required("La contraseña es requerida"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            remember: true,
        },
        validationSchema: LoginSchema,
        onSubmit: async () => {
            let response = await loginApi(formik.values.email, formik.values.password);
            response = JSON.parse(response);
            if (response.loggin) {
                authService.doLogIn(response.access_token);
                dispatch(setUser(response.access_token));
                const token = JSON.stringify(response.access_token);
                const user1 = await userApi(token);
                authService.doUserInfo(user1);
                dispatch(setUserInfo(user1));
                navigate('/dashboard', {replace: true});
            } else if (!response.loggin) {
                refresh();
            }
        },
    });

    const {errors, touched, values, isSubmitting, handleSubmit, getFieldProps} =
        formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                    component={motion.div}
                    animate={{
                        transition: {
                            staggerChildren: 0.55,
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 3,
                        }}
                        component={motion.div}
                        initial={{opacity: 0, y: 40}}
                        animate={animate}
                    >
                        <TextField
                            fullWidth
                            autoComplete="username"
                            type="email"
                            label="Correo electrónico"
                            {...getFieldProps("email")}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />

                        <TextField
                            fullWidth
                            autoComplete="current-password"
                            type={showPassword ? "text" : "password"}
                            label="Contraseña"
                            {...getFieldProps("password")}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword((prev) => !prev)}
                                        >
                                            {showPassword ? (
                                                <Icon icon="eva:eye-fill"/>
                                            ) : (
                                                <Icon icon="eva:eye-off-fill"/>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <Box
                        component={motion.div}
                        initial={{opacity: 0, y: 20}}
                        animate={animate}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 2}}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...getFieldProps("remember")}
                                        checked={values.remember}
                                    />
                                }
                                label="Recordarme"
                            />

                            <Link
                                component={RouterLink}
                                variant="subtitle2"
                                to="#"
                                underline="hover"
                            >
                                ¿Olvidaste tu contraseña?
                            </Link>
                        </Stack>

                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            {isSubmitting ? "loading..." : "Iniciar sesión"}
                        </LoadingButton>
                    </Box>
                </Box>
            </Form>
        </FormikProvider>
    );
};

export default LoginForm;
