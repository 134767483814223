import config from "../../config";

export const getDoctorApi = async (id) => {
    const token = localStorage.getItem('token');
    console.log("getDoctorApi", id);
    const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        Cookie: `XSRF-TOKEN=${token}`,
        'Content-Type': 'application/json',
    });
    const requestOptions = {method: 'GET', headers: myHeaders, redirect: 'follow'};
    try {
        const response = await fetch(`${config.config.serverUrl}api/doctor/${id}`, requestOptions)
            .then(response => response.json());
        return JSON.stringify(response);
    } catch (error) {
        console.log("error", error);
        return null;
    }
}
