import config from "../../config";

export const getPatientApi = async (id) => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers({
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    });
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${config.config.serverUrl}api/patient/${id}`, requestOptions)
            .then(response => response.json());
        return JSON.stringify(response);
    } catch (error) {
        console.log("error", error);
        return null;
    }
}
