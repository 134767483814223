import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Slider,
  Button,
  Link,
} from "@mui/material";
import {
  Close,
  NavigateBefore,
  NavigateNext,
  ZoomIn,
  ZoomOut,
  Delete,
  GetApp,
} from "@mui/icons-material";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ open, onClose, pdfFile, onDelete, url }) => {
  console.log("PDFViewer", { pdfFile, url });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [pageDimensions, setPageDimensions] = useState(null);
  const [renderedPageSize, setRenderedPageSize] = useState(null);

  const onRenderSuccess = ({ width, height }) => {
    console.log("onRenderSuccess", { width, height });
    setRenderedPageSize({ width, height });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onPageLoadSuccess(page) {
    hideAnnotationsLayer();
    setPageDimensions({ width: page.width, height: page.height });
  }

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      changePage(1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      changePage(-1);
    }
  };

  const onZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  const handleClickDownload = () => {
    const pdfData = new Blob([pdfFile], { type: "application/pdf" });
    const downloadUrl = URL.createObjectURL(pdfData);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "document.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(downloadUrl);
  };

  const handleClickDelete = () => {
    onDelete(pdfFile);
  };

  const hideAnnotationsLayer = () => {
    const annotationLayers = document.querySelectorAll('.react-pdf__Page__annotations');
    annotationLayers.forEach(layer => {
      layer.style.display = 'none';
    });
  }

  return (
    <Dialog maxWidth={false} open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button startIcon={<GetApp />} onClick={handleClickDownload}>
              Descargar
            </Button>
            <Button startIcon={<Delete />} onClick={handleClickDelete}>
              Eliminar
            </Button>
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          width: "70vw",
          height: "120vh",
          maxWidth: "70vw",
          maxHeight: "120vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            maxHeight: "auto", // o "100%"
            overflow: "auto",
            height: "auto", // o "100%"
            position: "relative",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
            height={"800"}
          >
            <Page
              pageNumber={pageNumber}
              scale={zoom}
              renderTextLayer={false}
              onLoadSuccess={onPageLoadSuccess}
              onRenderSuccess={onRenderSuccess}
              
              size="A4"
            />
          </Document>
        </Box>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            startIcon={<NavigateBefore />}
            onClick={prevPage}
            disabled={pageNumber <= 1}
          >
            Anterior
          </Button>
          <Typography variant="body1" display="inline" mx={2}>
            {pageNumber} de {numPages}
          </Typography>
          <Button
            startIcon={<NavigateNext />}
            onClick={nextPage}
            disabled={pageNumber >= numPages}
          >
            Siguiente
          </Button>
        </Box>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => setZoom((prevZoom) => prevZoom - 0.1)}
            disabled={zoom <= 0.5}
          >
            <ZoomOut />
          </IconButton>
          <Slider
            value={zoom}
            min={0.5}
            max={2}
            step={0.1}
            onChange={onZoomChange}
            sx={{ width: "50%" }}
          />
          <IconButton
            onClick={() => setZoom((prevZoom) => prevZoom + 0.1)}
            disabled={zoom >= 2}
          >
            <ZoomIn />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PDFViewer;