import './App.css';
import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Error404 from "./pages/error-page";
import ProtectedRoute from "./components/ProtectedRoute";
import {useSelector} from "react-redux";
import {ShowPatient} from "./pages/showPatient";
import Dashboard from "./pages/Dashboard";
import {CreatePatient} from "./pages/CreatePatient";
import Doctor from "./pages/Doctor";
import {ShowDoctor} from "./pages/showDoctor";
import {Specialties} from "./pages/Specialties";
import {Entity} from "./pages/Entity";
import CreateDoctor from "./pages/createDoctor";
import {ShowEntity} from "./pages/showEntity";
import {CreateEntity} from "./pages/createEntity";
import {PorTabular} from "./pages/reports/porTabular";
import {Retabulacion} from "./pages/reports/retabulacion";
import {Tabulado} from "./pages/reports/tabulado";
import {Cobranza} from "./pages/reports/cobranza";
import {PagadosACorte} from "./pages/reports/pagadosACorte";
import {CortePorLiquidar} from "./pages/reports/cortePorLiquidar";
import {CortePagado} from "./pages/reports/cortePagado";
import {Users} from "./pages/users";
import {Pacientes} from "./pages/Pacientes";

function App() {
    const {user} = useSelector(state => state.user);

    return (
        <Routes>
            <Route path='/' element={<Login/>}/>
            <Route
                path='home'
                element={
                    <ProtectedRoute user={user}>
                        <Home user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='dashboard'
                element={
                    <ProtectedRoute user={user}>
                        <Dashboard user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='pacientes'
                element={
                    <ProtectedRoute user={user}>
                        <Pacientes user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='patient'
                element={
                    <ProtectedRoute user={user}>
                        <ShowPatient user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='createPatient'
                element={
                    <ProtectedRoute user={user}>
                        <CreatePatient user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='doctors'
                element={
                    <ProtectedRoute user={user}>
                        <Doctor user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='doctor'
                element={
                    <ProtectedRoute user={user}>
                        <ShowDoctor user={user}/>
                    </ProtectedRoute>
                }
            />

            <Route
                path='createDoctor'
                element={
                    <ProtectedRoute user={user}>
                        <CreateDoctor user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='specialties'
                element={
                    <ProtectedRoute user={user}>
                        <Specialties user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='entities'
                element={
                    <ProtectedRoute user={user}>
                        <Entity user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='entity'
                element={
                    <ProtectedRoute user={user}>
                        <ShowEntity user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='createEntity'
                element={
                    <ProtectedRoute user={user}>
                        <CreateEntity user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='por-tabular'
                element={
                    <ProtectedRoute user={user}>
                        <PorTabular user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='retabulacion'
                element={
                    <ProtectedRoute user={user}>
                        <Retabulacion user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='tabulado'
                element={
                    <ProtectedRoute user={user}>
                        <Tabulado user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='cobranza'
                element={
                    <ProtectedRoute user={user}>
                        <Cobranza user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='pagados-corte'
                element={
                    <ProtectedRoute user={user}>
                        <PagadosACorte user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='corte-liquidar'
                element={
                    <ProtectedRoute user={user}>
                        <CortePorLiquidar user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='corte-pagado'
                element={
                    <ProtectedRoute user={user}>
                        <CortePagado user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route
                path='usuarios'
                element={
                    <ProtectedRoute user={user}>
                        <Users user={user}/>
                    </ProtectedRoute>
                }
            />
            <Route path='*' element={<Error404/>}/>
        </Routes>
    );
}

export default App;
