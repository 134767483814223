import {useNavigate} from "react-router-dom";

const doLogIn = (token) => {
    localStorage.setItem("token", token);
    localStorage.setItem("isLoggedIn", true);
};

const doUserInfo = (user) => {
    localStorage.setItem("userInfo", user);
}

const isLoggedIn = () => {
    return Boolean(localStorage.getItem("isLoggedIn"));
};

export default {
    doLogIn,
    isLoggedIn,
    doUserInfo
};
