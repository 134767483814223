import config from "../../config";

export const getFileApi = async (id, type) => {
  console.log(
    "getFileApi",
    id,
    type,
    "url",
    `${config.config.serverUrl}api/${type}/${id}`
  );
  const token = localStorage.getItem("token");
  const myHeaders = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/pdf",
  });
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${config.config.serverUrl}api/${type}/${id}`,
      requestOptions
    );

    if (response.ok) {
      if (type === "invoiceXml" || type === "xmlrep") {
        const fileBlob = await response.text();
        return fileBlob;
      } else {
        const fileBlob = await response.blob();
        return fileBlob;
      }
    } else {
      console.log(
        "Error al obtener el archivo. Código de respuesta:",
        response.status
      );
      return null;
    }
  } catch (error) {
    console.log("Error en la solicitud:", error);
    return null;
  }
};
