import config from "../../config";

export const updateDoctorApi = async (id, doctor) => {
  console.log("updateDoctorApi", id, doctor.email_secretary);
  const token = localStorage.getItem("token");

  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let raw = JSON.stringify({
    name: doctor.name.toUpperCase(),
    lastname: doctor.lastname.toUpperCase(),
    secondlastname: doctor.secondlastname.toUpperCase(),
    email: doctor.email,
    mobile: doctor.mobile,
    agreement: doctor.agreement,
    agreement_description: doctor.agreement_description,
    specialty_id: doctor.specialty_id,
    account_payment: doctor.account_payment,
    bank: doctor.bank.toUpperCase(),
    clabe: doctor.clabe,
    type_account: doctor.type_account,
    observations: doctor.observations.toUpperCase(),
    name_hospital: doctor.name_hospital.toUpperCase(),
    street: doctor.street.toUpperCase(),
    number: doctor.number,
    internal_number: doctor.internal_number,
    suburb_hospital: doctor.suburb_hospital.toUpperCase(),
    town_hospital: doctor.town_hospital.toUpperCase(),
    zip_hospital: doctor.zip_hospital,
    phone: doctor.phone,
    secretary: doctor.secretary.toUpperCase(),
    email_secretary: doctor.email_secretary,
    schedule: doctor.schedule,
    invoice: doctor.invoice,
    sat_link: doctor.sat_link,
    sat_user: doctor.sat_user,
    sat_key: doctor.sat_key,
    isr: doctor.isr,
    por_isr: doctor.por_isr,
    imp_local: doctor.imp_local,
    typeRegimen: doctor.typeRegimen,
    curp: doctor.curp.toUpperCase(),
    address: doctor.address.toUpperCase(),
    entities: doctor.entities,
    fax: doctor.fax,
    group: doctor.group,
    imp_localIV: doctor.imp_localIV,
    nextel: doctor.nextel,
    nextel_key: doctor.nextel_key,
    periodicity: doctor.periodicity,
    status: doctor.status,
    rfc: doctor.rfc.toUpperCase(),
    types_business: doctor.types_business,
    zip: doctor.zip,
  });

  let requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  console.log("requestOptions", requestOptions);

  const response = await fetch(
    `${config.config.serverUrl}api/updateDoctor/${id}`,
    requestOptions
  );
  console.log("response", response);
  return response.json();
};
