import React, { useEffect, useState } from 'react';
import { Box, Fab, Grid, Skeleton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Home from './Home';
import useWindowDimensions from '../functions/useWindowDimensions';
import { useNavigate, useLocation } from 'react-router-dom';
import { getEntityApi } from '../api/entities/getEntity';
import { InputController, SelectController } from '../atoms/AtomsForms';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { updateEntityApi } from "../api/entities/updateEntity";
import ContactsTable from '../components/tables/contactsTable';
import { FileModalEntity } from '../components/FileComponentEntity';

export const ShowEntity = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const [documents, setDocuments] = useState([]);
    const entityId = new URLSearchParams(location.search).get('id');
    console.log('entityId', entityId);
    const [entity, setEntity] = useState(null);
    console.log('isLoading', isLoading);

    const headers = [
        'id',
        'Nombre',
        'Puesto',
        'Email',
        'Telefono',
        'Estado',
    ];

    const rowsPerPageOptions = [20, 50, 100];

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('entityId2', entityId);
                const response = await getEntityApi(entityId);
                if (!response.error) {
                    setEntity(response.entity);
                    console.log('entity', response.contactos);

                    const headers = [
                        'nombre',
                        'puesto',
                        'email',
                        'telefono',
                        'estatus',
                    ];

                    const contactosArray = response.contactos.map(contacto => {
                        let contactoObj = {};
                        headers.forEach(header => {
                            if (header === 'estatus') {
                                contactoObj['estatus'] = contacto['estatus'] === '1' ? 'Activo' : 'Inactivo';
                            } else if (header !== 'acciones') {  // se asegura de que 'acciones' no se incluya
                                contactoObj[header] = contacto[header];
                            }
                        });
                        return contactoObj;
                    });

                    const documentosArray = response.documentos;
                    setDocuments(documentosArray);
                    console.log('documentosArray', documentosArray);

                    setRows(contactosArray);

                    setIsLoading(false);
                } else {
                    console.error('Error fetching entity:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching entity:', error);
            }
        };

        fetchData();
    }, [entityId]);

    const handleSave = async () => {
        const response = await updateEntityApi(entityId, entity);
        if (!response.error) {
            alert('Entidad actualizada correctamente');
        } else {
            alert('Error al actualizar la entidad');
        }
        window.location.reload();
    };

    const handleRowClick = (entitydata) => {
        console.log('Selected entity:', entitydata);
    }

    return (
        <Home
            title="Entidades"
            children={
                isLoading ? (
                    <Skeleton variant="rectangular" width={width / 1.1} height={height / 1.2} />
                ) : (
                    <>
                        <Fab
                            color="primary"
                            aria-label="add"
                            style={{
                                margin: 0,
                                top: "auto",
                                right: 20,
                                bottom: 20,
                                left: "auto",
                                position: "fixed",
                            }}
                        >
                            <IconButton
                                aria-label="save"
                                style={{ color: "white" }}
                                onClick={async () => {
                                    await handleSave();
                                }}
                            >
                                <SaveIcon />
                            </IconButton>
                        </Fab>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Datos generales
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Nombre"
                                                    value={entity?.name}
                                                    onChange={(e) => setEntity({ ...entity, name: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <SelectController
                                                    label="Tipo de entidad"
                                                    options={[
                                                        { id: 4, label: 'Aseguradora' },
                                                        { id: 5, label: 'Hospital' },
                                                        { id: 6, label: 'Empresas' },
                                                    ]}
                                                    value={
                                                        entity?.type_id === 4
                                                            ? { id: 4, label: 'Aseguradora' }
                                                            : entity?.type_id === 5
                                                                ? { id: 5, label: 'Hospital' }
                                                                : entity?.type_id === 6
                                                                    ? { id: 6, label: 'Empresas' }
                                                                    : null
                                                    }
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setEntity({ ...entity, type_id: newValue.id });
                                                        }
                                                    }}
                                                    style={{ width: '100%' }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Razón social"
                                                    value={entity?.business_name}
                                                    onChange={(e) => setEntity({ ...entity, business_name: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="RFC"
                                                    value={entity?.rfc}
                                                    onChange={(e) => setEntity({ ...entity, rfc: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Calle"
                                                    value={entity?.street}
                                                    onChange={(e) => setEntity({ ...entity, street: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Colonia"
                                                    value={entity?.suburb}
                                                    onChange={(e) => setEntity({ ...entity, suburb: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Municipio"
                                                    value={entity?.town}
                                                    onChange={(e) => setEntity({ ...entity, town: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Estado"
                                                    value={entity?.state}
                                                    onChange={(e) => setEntity({ ...entity, state: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Número exterior"
                                                    value={entity?.external_number}
                                                    onChange={(e) => setEntity({ ...entity, external_number: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Número interior"
                                                    value={entity?.internal_number}
                                                    onChange={(e) => setEntity({ ...entity, internal_number: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Código postal"
                                                    value={entity?.zip}
                                                    onChange={(e) => setEntity({ ...entity, zip: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Ciudad"
                                                    value={entity?.city}
                                                    onChange={(e) => setEntity({ ...entity, city: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="País"
                                                    value={entity?.country}
                                                    onChange={(e) => setEntity({ ...entity, country: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Contacto"
                                                    value={entity?.contact}
                                                    onChange={(e) => setEntity({ ...entity, contact: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box display="flex" justifyContent="center">
                                                <InputController
                                                    label="Observaciones"
                                                    fullWidth={true}
                                                    value={entity?.address_reception}
                                                    onChange={(e) => setEntity({ ...entity, address_reception: e.target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                Contactos
                            </AccordionSummary>
                            <AccordionDetails>
                                <ContactsTable headers={headers}
                                    rows={rows}
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    onRowClick={handleRowClick}
                                    entityId={entity.id}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                Archivos
                            </AccordionSummary>
                            <AccordionDetails>
                                <FileModalEntity doctor={entity} doctor_files={documents} />
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
            }
        />
    );
};
