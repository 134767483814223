import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Home from "./Home";
import {
  Box,
  Fab,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import authService from "../auth/authService";
import { setUser, setUserInfo } from "../slice/userSlice";
import MainCard from "../components/MainCard";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import { Autocomplete } from "@mui/lab";
import { savePatientApi } from "../api/patient/savePatientApi";
import { getDataCreatePatient } from "../api/patient/getDataCreatePatient";
import {
  setDoctors,
  setHospitals,
  setInsurances,
} from "../slice/patientsSlice";

export const CreatePatient = () => {
  const { userInfo } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = React.useState(true);
  const { doctors, hospitals, insurances } = useSelector(
    (state) => state.patients
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [patientData, setPatientData] = React.useState({
    authorization: "",
    authorization1: "",
    cancel_obs: "",
    comments: "",
    commission: "",
    created_at: "",
    date_attention: "",
    doctor_id: "",
    doctor_name: "",
    doctor_observation: "",
    folio: "",
    hospital_id: "",
    hospital_name: "",
    id: "",
    imp_local: "",
    insurance_id: "",
    insurance_name: "",
    invoice: "",
    isr: "",
    no_employee: "",
    no_order: "",
    observation: "",
    order: "",
    patient: "",
    payment_cutoff_date: "",
    payment_date: "",
    payment_due_date: "",
    payment_observation: "",
    payment_pdf: "",
    payment_type: "",
    payment_xml: "",
    portal_date: "",
    receipt: "",
    retabultation_obs: "",
    sinister: "",
    status_id: "",
    status_name: "",
    subtotal: "",
    tabulation: "",
    total: "",
    tracking: "",
    updated_at: "",
    xml: "",
  });

  const navigateToPatient = (id) => {
    console.log("navigateToPatient", id);
    navigate(`/patient?id=${id}`, { replace: true });
  };

  const optionEqualToValue = (option, value) => {
    return option.label === value;
  };

  const savePatient = async () => {
    const errors = validatePatientData(patientData);
    // Si hay errores, simplemente retorna y no continúa con la llamada API
    if (Object.keys(errors).length) {
      console.error("Errores en los datos del paciente:", errors);
      alert("Error al guardar, falta información del paciente");
      return;
    }

    let response = await savePatientApi(patientData);
    console.log("response", response);
    if (response.error) {
      alert(response.message);
      return;
    }
    console.log("response", response.patient.id);
    navigateToPatient(response.patient.id);
  };

  const validatePatientData = (data) => {
    let errors = {};

    if (!data.patient) errors.patient = "El nombre del paciente es requerido";
    if (!data.date_attention) errors.date_attention = "La fecha de atención es requerida";
    if (!data.doctor_id) errors.doctor_id = "El médico es requerido";
    if (!data.insurance_id) errors.insurance_id = "La aseguradora es requerida";
    if (!data.hospital_id) errors.hospital_id = "El hospital es requerido";
    if (!data.doctor_observation) errors.doctor_observation = "Los procedimientos realizados son requeridos";

    return errors;
  };

  const prepareDoctorSelect = (doctors) => {
    console.log("doctors", doctors);
    //Get doctors in array and prepare for select
    let doctorsSelect = [];
    doctors.forEach((doctor) => {
      console.log("doctors14", doctor);
      doctorsSelect.push({
        id: doctor.id,
        label: doctor.name,
        specialty: doctor.specialty
      });
    });
    console.log("doctorsSelect", doctorsSelect);
    return doctorsSelect;
  };

  const prepareHospitalSelect = (hospitals) => {
    //Get hospitals in array and prepare for select
    let hospitalsSelect = [];
    hospitals.forEach((hospital) => {
      hospitalsSelect.push({
        id: hospital.id,
        label: hospital.name,
      });
    });
    return hospitalsSelect;
  };

  const prepareInsuranceSelect = (insurances) => {
    //Get insurances in array and prepare for select
    let insurancesSelect = [];
    insurances.forEach((insurance) => {
      insurancesSelect.push({
        id: insurance.id,
        label: insurance.name,
      });
    });
    return insurancesSelect;
  };

  const getData = async () => {
    const isLoggin = authService.isLoggedIn();
    if (!isLoggin) {
      navigate("/", { replace: true });
    } else {
      const token = localStorage.getItem("token");
      dispatch(setUser(token));
      const userInfo = localStorage.getItem("userInfo");
      dispatch(setUserInfo(JSON.parse(userInfo)));
      const response = await getDataCreatePatient();
      const data = JSON.parse(response);
      console.log("data14", data);
      //Set doctors in redux
      console.log("data.doctors", data.doctors);
      dispatch(setDoctors(await prepareDoctorSelect(data.doctors)));
      //Set hospitals in redux
      console.log("data.hospitals", data.hospitals);
      dispatch(setHospitals(await prepareHospitalSelect(data.hospitals)));
      //Set insurances in redux
      console.log("data.insurances", data?.insurances);
      dispatch(setInsurances(await prepareInsuranceSelect(data.insurances)));
    }
  };

  const getPatient = async () => {
    if (!Object.keys(userInfo).length) {
      await getData();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const patient = async () => {
      await getPatient();
      setIsLoading(false);
    };
    patient();
  }, [userInfo]);

  return (
    <>
      {isLoading ? (
        <Home
          children={
            <div style={{ width: "100%" }}>
              <Box>Cargando...</Box>
            </div>
          }
          title={"Registro de paciente"}
        />
      ) : (
        <Home
          children={
            <div style={{ width: "100%" }}>
              <Fab
                color="primary"
                aria-label="add"
                style={{
                  margin: 0,
                  top: "auto",
                  right: 20,
                  bottom: 20,
                  left: "auto",
                  position: "fixed",
                }}
              >
                <IconButton
                  aria-label="save"
                  style={{ color: "white" }}
                  onClick={async () => {
                    await savePatient();
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Fab>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MainCard
                    title="Datos del paciente"
                    content={"Fecha de registro: " + patientData?.created_at}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8} lg={8}>
                        <Stack spacing={0.5}>
                          <InputLabel>Paciente</InputLabel>
                          <TextField
                            fullWidth
                            placeholder="Nombre del paciente"
                            value={patientData?.patient}
                            onChange={(e) =>
                              setPatientData({
                                ...patientData,
                                patient: e.target.value,
                              })
                            }
                          />
                          {!patientData?.patient ? (
                            <FormHelperText>Nombre del paciente</FormHelperText>
                          ) : null}
                        </Stack>
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 1,
                            m: 1,
                            bgcolor: "background.paper",
                            borderRadius: 1,
                          }}
                        >
                          <TextField
                            id="filled-search"
                            value={patientData?.date_attention}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                              setPatientData({
                                ...patientData,
                                date_attention: e.target.value,
                              })
                            }
                            label={"Fecha de atención"}
                            type={"date"}
                          />
                          <FormHelperText> </FormHelperText>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={0.5}>
                          <InputLabel>Médico</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={doctors}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                              setPatientData({
                                ...patientData,
                                doctor_name: newValue ? newValue.label : "",
                                doctor_id: newValue ? newValue.id : "",
                                specialty: newValue ? newValue.specialty : ""
                              });
                            }}
                            value={
                              patientData && patientData.doctor_id
                                ? doctors.find(
                                  (doctor) =>
                                    doctor.id === patientData.doctor_id
                                )
                                : null
                            }
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <FormHelperText>
                            <Typography
                              align="left"
                              variant="p"
                              component="div"
                              sx={{ fontWeight: "bold" }}
                            >
                              Especialidad: {patientData?.specialty}
                            </Typography>
                          </FormHelperText>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={0.5}>
                          <InputLabel>Estatus</InputLabel>
                          <Autocomplete
                            disablePortal
                            disabled={true}
                            id="combo-box-demo"
                            isOptionEqualToValue={optionEqualToValue}
                            options={{
                              id: 47,
                              label: "A. Por Tabular",
                            }}
                            value={{
                              id: 47,
                              label: "A. Por Tabular",
                            }}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <FormHelperText> </FormHelperText>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={0.5}>
                          <InputLabel>Aseguradora</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={insurances}
                            isOptionEqualToValue={optionEqualToValue}
                            onChange={(event, newValue) => {
                              setPatientData({
                                ...patientData,
                                insurance_id: newValue.id,
                                insurance_name: newValue.label,
                              });
                            }}
                            value={patientData?.insurance_name}
                            sx={{ width: "100%", overflow: "visible" }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <FormHelperText> </FormHelperText>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={0.5}>
                          <InputLabel>Hospital</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={hospitals}
                            isOptionEqualToValue={optionEqualToValue}
                            onChange={(event, newValue) => {
                              setPatientData({
                                ...patientData,
                                hospital_id: newValue.id,
                                hospital_name: newValue.label,
                              });
                            }}
                            value={patientData?.hospital_name}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <FormHelperText> </FormHelperText>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Stack spacing={0.5}>
                          <InputLabel>Procedimientos Realizados</InputLabel>
                          <TextField
                            id="filled-search"
                            value={patientData?.doctor_observation}
                            InputLabelProps={{ shrink: true }}
                            rows={3}
                            multiline
                            onChange={(e) =>
                              setPatientData({
                                ...patientData,
                                doctor_observation: e.target.value,
                              })
                            }
                            type={"textarea"}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
              </Grid>
            </div>
          }
          title={"Registro de paciente"}
        />
      )}
    </>
  );
};
