import React, {useEffect, useState} from "react";
import Home from "./Home";
import {
    Skeleton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Fab
} from "@mui/material";
import DynamicTable from "../components/tables/DynamicTable";
import useWindowDimensions from "../functions/useWindowDimensions";
import {getSpecialtiesApi} from "../api/specialties/getSpecialties";
import {InputController, SelectController} from "../atoms/AtomsForms";
import {updateSpecialtyApi} from "../api/specialties/updateSpecialtyApi";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {createSpecialtyApi} from "../api/specialties/createSpecialtyApi";

export const Specialties = () => {
    const rowsPerPageOptions = [20, 50, 100];
    const {height, width} = useWindowDimensions();
    const [specialties, setSpecialties] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const headers = ["id", "Especialidad", "Estatus"];
    const [specialtyName, setSpecialtyName] = useState("");

    const handleDoctorRowClick = (specialtyInfo) => {
        // setSelectedSpecialty(specialtyInfo);
        // console.log(specialtyInfo);
        // setSpecialtyName(specialtyInfo[1]);
        // setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const extractSpecialtyInfo = ({id, name, status}) => [
        id,
        name,
        status === 1 ? "Activo" : "Inactivo",
    ];

    const handleNewSpecialtyClick = () => {
        setSelectedSpecialty({1: "", 2: "Activo"});  // Define el estado inicial para un nuevo objeto
        setOpen(true);
    };

    const handleSave = async () => {
        if (selectedSpecialty[0]) {
            console.log('update', selectedSpecialty)
            await updateSpecialty();
        } else {
            console.log('create', selectedSpecialty)
            await createSpecialty();
        }
        handleClose();
    };

    const createSpecialty = async () => {
        console.log('create', selectedSpecialty);
        // Aquí debes llamar a la API para crear la nueva especialidad

        const response = await createSpecialtyApi(selectedSpecialty);
        console.log('response', response);
        if (!response.error) {
            alert('Especialidad creada correctamente');
            window.location.reload();
        } else {
            alert('Error al crear la especialidad');
        }

    };


    const updateSpecialty = async () => {
        console.log('update', selectedSpecialty);
        const response = await updateSpecialtyApi(selectedSpecialty);
        console.log('response', response);
        if (!response.error) {
            alert('Especialidad actualizada correctamente');
            window.location.reload();
        } else {
            alert('Error al actualizar la especialidad');
        }
    };

    useEffect(() => {
        const fetchSpecialties = async () => {
            setIsLoading(true);
            try {
                const specialtyData = await getSpecialtiesApi();
                if (specialtyData && specialtyData.specialities) {
                    setSpecialties(specialtyData.specialities.map(extractSpecialtyInfo));
                } else {
                    console.error("Error: No se pudo obtener la información de las especialidades.");
                }
            } catch (error) {
                console.error("Error al obtener las especialidades:", error);
            }
            setIsLoading(false);
        };

        fetchSpecialties();
    }, []);

    return (
        <Home
            title="Especialidades"
            children={
                <>
                    {isLoading ? (
                        <Skeleton variant="rectangular" width={width / 1.1} height={height / 1.2}/>
                    ) : (
                        <>
                            <Fab color="primary" aria-label="add" style={{
                                margin: 0,
                                top: 'auto',
                                right: 20,
                                bottom: 20,
                                left: 'auto',
                                position: 'fixed',
                            }}>
                                <IconButton aria-label="save" style={{color: "white"}}
                                            onClick={handleNewSpecialtyClick}>
                                    <AddIcon/>
                                </IconButton>
                            </Fab>
                            <DynamicTable
                                headers={headers}
                                rows={specialties}
                                rowsPerPageOptions={rowsPerPageOptions}
                                onRowClick={handleDoctorRowClick}
                            />
                        </>
                    )}
                    <Dialog open={open} onClose={handleClose} style={{justifyContent: 'center'}}>
                        <DialogTitle>{selectedSpecialty ? `Especialidad: ${specialtyName}` : 'Especialidad'}
                            <br/>
                        </DialogTitle>
                        <DialogContent style={{width: '400px', justifyContent: 'center'}}>
                            {selectedSpecialty && (
                                <DialogContentText>
                                    <InputController
                                        label="Especialidad:"
                                        value={selectedSpecialty?.[1]}
                                        onChange={(e) => setSelectedSpecialty({
                                            ...selectedSpecialty,
                                            1: e.target.value
                                        })}
                                    /> <br/>
                                    <SelectController
                                        label="Estatus:"
                                        options={[
                                            {id: 1, label: 'Activo'},
                                            {
                                                id: 0,
                                                label: 'Inactivo'
                                            }
                                        ]}
                                        value={
                                            selectedSpecialty[2] === "Activo"
                                                ? {id: 1, label: 'Activo'}
                                                : {
                                                    id: 0,
                                                    label: 'Inactivo'
                                                }
                                        }
                                        onChange={(event, newValue) => {
                                            console.log(newValue);
                                        }}
                                    />
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSave}>Guardar</Button>
                            <Button onClick={handleClose}>Cerrar</Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
        />
    );
};
