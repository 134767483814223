import config from "../../config";

export const getEntityApi = async (id) => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        Cookie: `XSRF-TOKEN=${token}`,
        'Content-Type': 'application/json',
    });

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${config.config.serverUrl}api/entityApi/${id}`, requestOptions);
        const result = await response.json();
        console.log('result', result);
        return result;
    } catch (error) {
        console.log('Error fetching entity:', error);
        throw error;
    }
}
