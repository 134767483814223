import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientApi } from "../api/patient/getPatientApi";
import Home from "./Home";
import { Box, Fab, Grid, InputLabel, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import authService from "../auth/authService";
import { setUser, setUserInfo } from "../slice/userSlice";
import MainCard from "../components/MainCard";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AttachmentIcon from "@mui/icons-material/Attachment";
import SaveIcon from "@mui/icons-material/Save";
import { Autocomplete } from "@mui/material";
import FileUploader from "../functions/uploadFile";
import { updatePatientApi } from "../api/patient/updatePatientApi";
import PDFViewer from "../components/PDFViewer";
import { setDoctors, setHospitals, setInsurances, setStatus } from "../slice/patientsSlice";
import { getFileApi } from "../api/patientFiles/fileApi";
import { FileModalPatient } from "../components/FileComponentPatient";

export const ShowPatient = () => {
  const { userInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { doctors, hospitals, insurances, status } = useSelector((state) => state.patients);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [documents, setDocuments] = useState([]);
  const [type, setType] = useState("");

  const handleUpload = (uploadedFile, fileId) => {
    setPatientData((prevState) => ({
      ...prevState,
      [fileId]: uploadedFile,
    }));
  };

  const [patientData, setPatientData] = React.useState({
    authorization: "",
    authorization1: "",
    cancel_obs: "",
    comments: "",
    commission: "",
    created_at: "",
    date_attention: "",
    doctor_id: "",
    doctor_name: "",
    doctor_observation: "",
    folio: "",
    hospital_id: "",
    hospital_name: "",
    id: "",
    imp_local: "",
    insurance_id: "",
    insurance_name: "",
    invoice: "",
    isr: "",
    no_employee: "",
    no_order: "",
    observation: "",
    order: "",
    patient: "",
    payment_cutoff_date: "",
    payment_date: "",
    payment_due_date: "",
    payment_observation: "",
    payment_pdf: "",
    payment_type: "",
    payment_xml: "",
    portal_date: "",
    receipt: "",
    retabultation_obs: "",
    sinister: "",
    status_id: "",
    status_name: "",
    subtotal: "",
    tabulation: "",
    total: "",
    tracking: "",
    updated_at: "",
    xml: "",
    comision: "",
    doctor_specialty: "",
  });

  const showFile = async (type) => {
    let url = await getFileApi(patientData.id, type);
    setPdfFile(url);
    setType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const onDelete = async (fileUrl) => {
    //Remove file from patientData
    let patientDataCopy = { ...patientData };
    let fileKey = Object.keys(patientDataCopy).find(
      (key) => patientDataCopy[key] === fileUrl
    );
    delete patientDataCopy[fileKey];
    setPatientData(patientDataCopy);
    //Remove file from server
    let response = await updatePatientApi(patientData);
    //Recargar la pagina
    window.location.reload();
  };

  const getData = async () => {
    const isLoggin = authService.isLoggedIn();
    if (!isLoggin) {
      navigate("/", { replace: true });
    } else {
      const token = localStorage.getItem("token");
      dispatch(setUser(token));
      const userInfo = localStorage.getItem("userInfo");
      dispatch(setUserInfo(JSON.parse(userInfo)));
    }
  };

  const updatePatient = async () => {
    //Eliminar el atributo de los archivos si no se subio nada
    if (patientData.tabulation && patientData.tabulation.includes(".pdf")) {
      delete patientData.tabulation;
    }
    if (patientData.invoice && patientData.invoice.includes(".pdf")) {
      delete patientData.invoice;
    }
    if (patientData.xml && patientData.xml.includes(".xml")) {
      delete patientData.xml;
    }
    if (patientData.payment_pdf && patientData.payment_pdf.includes(".pdf")) {
      delete patientData.payment_pdf;
    }
    if (patientData.payment_xml && patientData.payment_xml.includes(".xml")) {
      delete patientData.payment_xml;
    }

    //Validar fechas
    patientData.date_attention = validateDate(patientData.date_attention);
    patientData.payment_cutoff_date = validateDate(
      patientData.payment_cutoff_date
    );
    patientData.payment_date = validateDate(patientData.payment_date);
    patientData.payment_due_date = validateDate(patientData.payment_due_date);
    patientData.portal_date = validateDate(patientData.portal_date);

    

    console.log("patientData", patientData);
    let response = await updatePatientApi(patientData);
    console.log("response", response);
    //Recargar la pagina
    window.location.reload();
  };

  const updateValues = (subtotal) => {
    // Calculamos el ISR y lo redondeamos a dos decimales
    let isr = parseFloat((subtotal * (patientData.isr_percentage / 100)).toFixed(2));

    // Calculamos el impuesto local y lo redondeamos a dos decimales
    // Solo si aplica el impuesto local
    let imp_local = patientData.imp_local_doctor === 1 ? parseFloat((subtotal * (patientData.percentage / 100)).toFixed(2)) : 0;

    // Calculamos el total restando el ISR y el impuesto local del subtotal
    let total = parseFloat((subtotal - isr - imp_local).toFixed(2));

    // Calculamos la comisión y la redondeamos a dos decimales
    let comision = parseFloat((total * (patientData.comision / 100)).toFixed(2));

    setPatientData({
      ...patientData,
      subtotal: subtotal,
      isr: isr,
      total: total,
      commission: comision,
      imp_local: imp_local,
    });
  };

  const prepareDoctorSelect = (doctors) => {
    //Get doctors in array and prepare for select
    let doctorsSelect = [];
    doctors.forEach((doctor) => {
      doctorsSelect.push({
        id: doctor.id,
        label: doctor.name,
      });
    });
    return doctorsSelect;
  };

  const prepareHospitalSelect = (hospitals) => {
    //Get hospitals in array and prepare for select
    let hospitalsSelect = [];
    hospitals.forEach((hospital) => {
      hospitalsSelect.push({
        id: hospital.id,
        label: hospital.name,
      });
    });
    return hospitalsSelect;
  };

  const prepareInsuranceSelect = (insurances) => {
    //Get insurances in array and prepare for select
    let insurancesSelect = [];
    insurances.forEach((insurance) => {
      insurancesSelect.push({
        id: insurance.id,
        label: insurance.name,
      });
    });
    return insurancesSelect;
  };

  const prepareStatusSelect = (status) => {
    //Get status in array and prepare for select
    let statusSelect = [];
    status.forEach((status) => {
      statusSelect.push({
        id: status.id,
        label: status.name,
      });
    });
    return statusSelect;
  };

  // const prepareDocuments = (documents) => {
  //   //Get documents in array and prepare for select
  //   let documentsSelect = [];
  //   documents.forEach((document) => {
  //     documentsSelect.push({
  //       patient_id: document.patient_id,
  //       label: document.description,
  //       url: document.file,
  //     });
  //   });
  //   console.log("documentsSelect", documentsSelect);
  //   return documentsSelect;
  // }

  const validateDate = (date) => {
    const isValidDate = !isNaN(Date.parse(date));
    return isValidDate ? date : null;
  };

  let patientResponse;
  const getPatient = async () => {
    if (!Object.keys(userInfo).length) {
      await getData();
    }
    patientResponse = await getPatientApi(id, userInfo.id);
    patientResponse = JSON.parse(patientResponse);
    console.log("patientResponse", patientResponse);
    setPatientData({
      authorization: patientResponse.patient.authorization,
      authorization1: patientResponse.patient.authorization1,
      cancel_obs: patientResponse.patient.cancel_obs,
      comments: patientResponse.patient.comments,
      commission: patientResponse.patient.commission,
      created_at: patientResponse.patient.created_at,
      date_attention: dayjs(patientResponse.patient.date_attention).format("YYYY-MM-DD"),
      doctor_id: patientResponse.patient.doctor_id,
      doctor_name: patientResponse.patient.doctor_name,
      doctor_observation: patientResponse.patient.doctor_observation,
      folio: patientResponse.patient.folio,
      hospital_id: patientResponse.patient.hospital_id,
      hospital_name: patientResponse.patient.hospital_name,
      id: patientResponse.patient.id,
      imp_local: patientResponse.patient.imp_local,
      insurance_id: patientResponse.patient.insurance_id,
      insurance_name: patientResponse.patient.insurance_name,
      invoice: patientResponse.patient.invoice,
      isr: patientResponse.patient.isr,
      no_employee: patientResponse.patient.no_employee,
      no_order: patientResponse.patient.no_order,
      observation: patientResponse.patient.observation,
      order: patientResponse.patient.order,
      patient: patientResponse.patient.patient,
      payment_cutoff_date: dayjs(patientResponse.patient.payment_cutoff_date).format("YYYY-MM-DD"),
      payment_date: dayjs(patientResponse.patient.payment_date).format("YYYY-MM-DD"),
      payment_due_date: dayjs(patientResponse.patient.payment_due_date).format("YYYY-MM-DD"),
      payment_observation: patientResponse.patient.payment_observation,
      payment_pdf: patientResponse.patient.payment_pdf,
      payment_type: patientResponse.patient.payment_type,
      payment_xml: patientResponse.patient.payment_xml,
      portal_date: dayjs(patientResponse.patient.portal_date).format("YYYY-MM-DD"),
      receipt: patientResponse.patient.receipt,
      retabultation_obs: patientResponse.patient.retabultation_obs,
      sinister: patientResponse.patient.sinister,
      status_id: patientResponse.patient.status_id,
      status_name: patientResponse.patient.status_name,
      subtotal: patientResponse.patient.subtotal,
      tabulation: patientResponse.patient.tabulation,
      total: patientResponse.patient.total,
      tracking: patientResponse.patient.tracking,
      updated_at: patientResponse.patient.updated_at,
      xml: patientResponse.patient.xml,
      percentage: patientResponse.patient.percentage,
      isr_percentage: patientResponse.patient.isr_percentage,
      comision: patientResponse.patient.catalog_details[0].description,
      imp_local_doctor: patientResponse.patient.imp_local_doctor,
      doctor_specialty: patientResponse.patient.doctor_specialty,
    });

    console.log("patientData", patientData);

    //Set doctors in redux
    dispatch(setDoctors(await prepareDoctorSelect(patientResponse.doctors)));
    //Set hospitals in redux
    dispatch(setHospitals(await prepareHospitalSelect(patientResponse.hospitals)));
    //Set insurances in redux
    dispatch(setInsurances(await prepareInsuranceSelect(patientResponse.insurances)));
    //Set status in redux
    dispatch(setStatus(await prepareStatusSelect(patientResponse.status)));

    //Set documents
    // setDocuments(prepareDocuments(patientResponse.documents));
    // dispatch(setDocuments(await prepareDocuments(patientResponse.documents)));
    setDocuments(patientResponse.documents);

    console.log("documentsSet", documents);
  };

  useEffect(() => {
    const patient = async () => {
      getPatient();
    };
    patient();
  }, [userInfo]);

  return (
    <>
      <Home
        children={
          <div style={{ width: "100%" }}>
            <Fab
              color="primary"
              aria-label="add"
              style={{
                margin: 0,
                top: "auto",
                right: 20,
                bottom: 20,
                left: "auto",
                position: "fixed",
              }}
            >
              <IconButton
                aria-label="save"
                style={{ color: "white" }}
                onClick={() => {
                  updatePatient();
                }}
              >
                <SaveIcon />
              </IconButton>
            </Fab>
            <PDFViewer
              open={open}
              onClose={handleClose}
              pdfFile={pdfFile}
              onDelete={onDelete}
              type={type}
            />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MainCard
                  title="Datos del paciente"
                  subheader={
                    "Consecutivo: " +
                    patientData?.order +
                    "  /  " +
                    "Fecha de registro: " +
                    dayjs(patientData?.created_at).format("DD/MM/YYYY")
                  }
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Paciente"
                        placeholder="Nombre del paciente"
                        value={patientData?.patient}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            patient: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="filled-search"
                        label="Fecha de atención"
                        type="date"
                        value={patientData?.date_attention}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            date_attention: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={doctors}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setPatientData({
                            ...patientData,
                            doctor_name: newValue ? newValue.label : "",
                            doctor_id: newValue ? newValue.id : "",
                          });
                        }}
                        value={
                          patientData && patientData.doctor_id
                            ? doctors.find(
                              (doctor) => doctor.id === patientData.doctor_id
                            )
                            : null
                        }
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Médico" />
                        )}
                      />
                      <Typography
                        align="left"
                        variant="subtitle2"
                        component="div"
                        sx={{ fontWeight: "bold" }}
                      >
                        Especialidad: {patientData?.doctor_specialty}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={status}
                        onChange={(event, newValue) => {
                          setPatientData({
                            ...patientData,
                            status_id: newValue.id,
                            status_name: newValue.label,
                          });
                        }}
                        value={patientData?.status_name}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Estatus" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={insurances}
                        onChange={(event, newValue) => {
                          setPatientData({
                            ...patientData,
                            insurance_id: newValue.id,
                            insurance_name: newValue.label,
                          });
                        }}
                        value={patientData?.insurance_name}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Aseguradora" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={hospitals}
                        onChange={(event, newValue) => {
                          setPatientData({
                            ...patientData,
                            hospital_id: newValue.id,
                            hospital_name: newValue.label,
                          });
                        }}
                        value={patientData?.hospital_name}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Hospital" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Procedimientos Realizados"
                        value={patientData?.doctor_observation}
                        InputLabelProps={{ shrink: true }}
                        rows={3}
                        multiline
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            doctor_observation: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </MainCard>
              </Grid>
              <Grid item xs={12}>
                <MainCard title="Información de seguimiento">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        label="Seguimiento"
                        id="filled-search"
                        value={patientData?.tracking}
                        InputLabelProps={{ shrink: true }}
                        rows={3}
                        fullWidth
                        multiline
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            tracking: e.target.value,
                          })
                        }
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Siniestro"
                        id="filled-search"
                        value={patientData?.sinister}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            sinister: e.target.value,
                          })
                        }
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Folio / Autorización"
                        id="filled-search"
                        value={patientData?.folio}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            folio: e.target.value,
                          })
                        }
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Otro"
                        id="filled-search"
                        value={patientData?.authorization1}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            authorization1: e.target.value,
                          })
                        }
                        type="text"
                      />
                    </Grid>

                  </Grid>
                  <Grid container spacing={2} marginTop={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        fullWidth
                        label="Subtotal"
                        id="filled-search"
                        value={patientData?.subtotal}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          updateValues(e.target.value, "subtotal")
                        }
                        type="number"
                      />
                    </Grid>
                    {patientData?.imp_local_doctor === 1 || patientData?.imp_local_doctor === "1" ? (
                      <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          fullWidth
                          label={"Impuesto local " + patientData?.percentage + "%"}
                          id="filled-search"
                          value={patientData?.imp_local}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) =>
                            setPatientData({
                              ...patientData,
                              imp_local: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        fullWidth
                        label={"ISR " + patientData?.isr_percentage + "%"}
                        id="filled-search"
                        value={patientData?.isr}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            isr: e.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        fullWidth
                        label="Total"
                        id="filled-search"
                        value={patientData?.total}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            total: e.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    {userInfo.role_id === 1 ? (
                      <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          fullWidth
                          label={"Comisión " + patientData?.comision + "%"}
                          id="filled-search"
                          value={patientData?.commission}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) =>
                            setPatientData({
                              ...patientData,
                              commission: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        p: 1,
                        m: 1,
                        bgcolor: "background.paper",
                        borderRadius: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          p: 1,
                          m: 1,
                        }}
                      >
                        <Typography
                          variant="p"
                          component="div"
                          sx={{ fontWeight: "bold" }}
                        >
                          Tabulación:
                        </Typography>
                        {patientData?.tabulation ? (
                          <IconButton
                            aria-label="search"
                            size="large"
                            onClick={async () => {
                              await showFile("tabulation");
                            }}
                          >
                            <PictureAsPdfIcon sx={{ color: 'red' }} />
                          </IconButton>
                        ) : (
                          <FileUploader
                            key="tabulation"
                            onUpload={handleUpload}
                            fileId="tabulation"
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          p: 1,
                          m: 1,
                        }}
                      >
                        <Box>
                          {patientData?.invoice ? (
                            <IconButton
                              aria-label="search"
                              size="large"
                              onClick={async () => {
                                await showFile("invoice");
                              }}
                            >
                              <PictureAsPdfIcon sx={{ color: 'red' }} />
                            </IconButton>
                          ) : (
                            <FileUploader
                              key="invoice"
                              onUpload={handleUpload}
                              fileId="invoice"
                            />
                          )}
                          {patientData?.xml ? (
                            <IconButton
                              aria-label="search"
                              size="large"
                              onClick={async () => {
                                await showFile("invoiceXml");
                              }}
                            >
                              <AttachmentIcon sx={{ color: '#5FF779' }} />
                            </IconButton>
                          ) : (
                            <FileUploader
                              key="xml"
                              onUpload={handleUpload}
                              fileId="xml"
                            />
                          )}
                        </Box>
                        <TextField
                          id="filled-search"
                          value={patientData?.receipt}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) =>
                            setPatientData({
                              ...patientData,
                              receipt: e.target.value,
                            })
                          }
                          label="Factura"
                          type="number"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          p: 1,
                          m: 1,
                        }}
                      >
                        <TextField
                          id="filled-search"
                          value={patientData?.portal_date ? patientData.portal_date : ""}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            const dateValue = e.target.value;
                            const isValidDate = !isNaN(Date.parse(dateValue));
                            setPatientData({
                              ...patientData,
                              portal_date: isValidDate ? dateValue : null,
                            });
                          }}
                          label="Fecha de portal"
                          type="date"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          p: 1,
                          m: 1,
                        }}
                      >
                        <Box>
                          {patientData?.payment_pdf ? (
                            <IconButton
                              aria-label="search"
                              size="large"
                              onClick={async () => {
                                await showFile("pdfrep");
                              }}
                            >
                              <PictureAsPdfIcon sx={{ color: 'red' }} />
                            </IconButton>
                          ) : (
                            <FileUploader
                              key="payment_pdf"
                              onUpload={handleUpload}
                              fileId="payment_pdf"
                            />
                          )}
                          {patientData?.payment_xml ? (
                            <IconButton
                              aria-label="search"
                              size="large"
                              onClick={async () => {
                                await showFile("xmlrep");
                              }}
                            >
                              <AttachmentIcon sx={{ color: '#5FF779' }} />
                            </IconButton>
                          ) : (
                            <FileUploader
                              key="payment_xml"
                              onUpload={handleUpload}
                              fileId="payment_xml"
                            />
                          )}
                        </Box>
                        <TextField
                          id="filled-search"
                          value={patientData?.comments}
                          onChange={(e) =>
                            setPatientData({
                              ...patientData,
                              comments: e.target.value,
                            })
                          }
                          InputLabelProps={{ shrink: true }}
                          label="REP"
                          type="text"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          p: 1,
                          m: 1,
                        }}
                      >
                        <TextField
                          id="filled-search"
                          value={patientData?.payment_due_date}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) =>
                            setPatientData({
                              ...patientData,
                              payment_due_date: e.target.value,
                            })
                          }
                          label="Fecha de REP"
                          type="date"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </MainCard>
              </Grid>
              <Grid item xs={12}>
                <MainCard title="Pagos">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} lg={3}>
                      <InputLabel>Fecha de pago</InputLabel>
                      <TextField
                        id="filled-search"
                        value={patientData?.payment_date}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            payment_date: e.target.value,
                          })
                        }
                        type="date"
                      />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      <InputLabel>Observaciones del pago</InputLabel>
                      <TextField
                        id="filled-search"
                        value={patientData?.payment_observation}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={2}
                        onChange={(e) =>
                          setPatientData({
                            ...patientData,
                            payment_observation: e.target.value,
                          })
                        }
                        type="textarea"
                        sx={{ width: "70%" }}
                      />
                    </Grid>
                  </Grid>
                </MainCard>
              </Grid>
              <Grid item xs={12}>
                <MainCard title="Documentos">
                  <FileModalPatient doctor={patientData} doctor_files={documents} />
                </MainCard>
              </Grid>
            </Grid>
          </div>
        }
        title={"Registro de paciente"}
      />
    </>
  );
};
