import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Box,
} from "@mui/material";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";

import config from "../../config";
import configApp from "../../config";

const PasswordsInsuranceTable = ({ headers, rows, rowsPerPageOptions, onDelete }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box style={{ paddingBottom: 70 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: configApp.styles.principalColor,
            }}
          >
            <TableRow>
              {headers.map((header, index) =>
                header !== "id" ? (
                  <TableCell sx={{ color: "black" }} key={index}>
                    {header}
                  </TableCell>
                ) : null
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) =>
                    cellIndex !== headers.indexOf("id") ? (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ) : null
                  )}
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={({
                  count,
                  page,
                  rowsPerPage,
                  onPageChange,
                }) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <IconButton
                      onClick={(event) => onPageChange(event, 0)}
                      disabled={page === 0}
                    >
                      <FirstPage />
                    </IconButton>
                    <IconButton
                      onClick={(event) => onPageChange(event, page - 1)}
                      disabled={page === 0}
                    >
                      <NavigateBefore />
                    </IconButton>
                    <IconButton
                      onClick={(event) => onPageChange(event, page + 1)}
                      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    >
                      <NavigateNext />
                    </IconButton>
                    <IconButton
                      onClick={(event) =>
                        onPageChange(event, Math.ceil(count / rowsPerPage) - 1)
                      }
                      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    >
                      <LastPage />
                    </IconButton>
                  </div>
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default PasswordsInsuranceTable;
