import config from '../../config';

export const saveDoctorApi = async (doctor) => {
    const token = localStorage.getItem('token');
    console.log('saveDoctorApi', doctor);
    let raw = JSON.stringify({
        name: doctor.name.toUpperCase(),
        lastname: doctor.lastname.toUpperCase(),
        secondlastname: doctor.secondlastname.toUpperCase(),
        email: doctor.email,
        mobile: doctor.mobile,
        agreement: doctor.agreement,
        specialty_id: doctor.specialty_id,
    });

    const requestOptions = {
        method: 'POST',
        body: raw,
        headers: {
            Authorization: `Bearer ${token}`,
            Cookie: `XSRF-TOKEN=${token}`,
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
    };

    const response = await fetch(`${config.config.serverUrl}api/createDoctor`, requestOptions);
    console.log('response', response);
    return response.json();
};
