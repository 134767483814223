import config from "../../config";

export const savePatientApi = async (data) => {
    const token = localStorage.getItem('token');
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
        patient: data.patient.toUpperCase(),
        doctor_id: data.doctor_id,
        insurance_id: data.insurance_id,
        hospital_id: data.hospital_id,
        date_attention: data.date_attention,
        status_id: 47,
        doctor_observation: data.doctor_observation,
    });

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response;
    response = await fetch(`${config.config.serverUrl}api/createPatient`, requestOptions)
    return response.json();
}
