import config from "../../config";

export const loginApi = async (email, password) => {
    const myHeaders = new Headers({"Content-Type": "application/json", Accept: "application/json",});
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({email, password}),
        redirect: "follow",
    };
    try {
        const response = await fetch(`${config.config.serverUrl}api/auth/login`, requestOptions);
        const data = await response.json();
        return JSON.stringify(data);
    } catch (error) {
        console.log("error", error);
        return null;
    }
};
