import {createSlice} from "@reduxjs/toolkit";


export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {},
        userInfo: {},
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
    },
});
export const selectUser = (state) => state.user;
export const {setUser, setUserInfo} = userSlice.actions;
export default userSlice.reducer;
