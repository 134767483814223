import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Box,
    TextField,
    Button,
} from "@mui/material";
import PatientAccordion from "../../components/tables/tableCortePorLiquidar";
import Home from "../Home";
import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../functions/useWindowDimensions";
import { getCortePorLiquidarApi } from "../../api/reportes/cortePorLiquidarApi";
import formatMoney from "../../functions/formatMoney";

export const CortePorLiquidar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [amounts, setAmounts] = useState({});
    const [filter, setFilter] = useState({
        patient: "",
        doctor: "",
        insurance: "",
        hospital: "",
    });
    const [update, setUpdate] = useState(false);
    const { height, width } = useWindowDimensions();
    const rowsPerPageOptions = [20, 50, 100];
    const [patients, setPatients] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1, // Inicia en la página 1
        first_page_url: "",
        last_page: "",
        last_page_url: "",
        next_page_url: "",
        path: "",
        per_page: rowsPerPageOptions[0],
        prev_page_url: "",
        to: "",
        total: "",
    });

    const n = 20;
    const m = 3;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const headers = [
        "id",
        "#",
        "Médico",
        "Paciente",
        "Consecutivo",
        "Recibo",
        "Aseguradora",
        "REP",
        "Fecha de pago",
        "Subtotal",
        "ISR",
        "Total",
        "Comisión",
    ];
    const cellStyle = {
        padding: "10px",
        backgroundColor: "transparent",
        position: "relative",
    };

    const skeletonStyle = {
        backgroundColor: "#f5f5f5",
        animationDuration: "2s",
    };

    const extractPatientInfo = (patient) => {
        const createdAt = new Date(patient.created_at);
        const day = String(createdAt.getDate()).padStart(2, "0");
        const month = String(createdAt.getMonth() + 1).padStart(2, "0");
        const year = createdAt.getFullYear();
        const formattedCreatedAt = `${day}/${month}/${year}`;
        console.log("patient", patient);

        return [
            patient.id,
            patient.doctor_name,
            patient.patient,
            patient.order,
            patient.receipt,
            patient.aseguradora_name,
            patient.payment_due_date,
            patient.payment_date,
            formatMoney(patient.subtotal),
            formatMoney(patient.isr),
            formatMoney(patient.total),
            formatMoney(patient.commission),
        ];
    };

    const fetchData = async (page, rowsPerPage) => {
        setIsLoading(true);
        let paginatedData = await getCortePorLiquidarApi(filter, page, rowsPerPage);
        paginatedData = JSON.parse(paginatedData);
        console.log("paginatedData", paginatedData);
        let amountFormated = paginatedData.amounts;
        for (var key in amountFormated) {
            amountFormated[key] = formatMoney(amountFormated[key]);
        }
        console.log("amountFormated", amountFormated);
        setAmounts(amountFormated);
        console.log("amounts", amounts);
        paginatedData = paginatedData.patients;
        console.log("paginatedData1", paginatedData);
        console.log("paginatedData2", paginatedData);
        if (paginatedData) {
            console.log("data", paginatedData);
            const patientInfo = paginatedData
            console.log("patientInfo", patientInfo);
            setPatients(patientInfo);
            console.log("patients", patients);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData(page, rowsPerPage, filter);
    }, [page, rowsPerPage, update]);

    const handlePatientClick = (patientsInfo) => {
        console.log("patientsInfo", patientsInfo);
    };

    const handlePageChange = (page, rowsPerPage) => {
        setPage(page);
        setRowsPerPage(rowsPerPage);
    };

    return (
        <Home
            title="Reporte corte por liquidar"
            children={
                <>
                    {isLoading ? (
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {[...Array(n)].map((_, i) => (
                                        <TableRow key={i}>
                                            {[...Array(m)].map((_, j) => (
                                                <TableCell key={j} sx={cellStyle}>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        width={200}
                                                        height={30}
                                                        sx={skeletonStyle}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    width: "100%",
                                    marginBottom: 2,
                                    flexDirection: "row",
                                    display: "flex",
                                    padding: 3,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Buscar médico"
                                    value={filter.doctor}
                                    onChange={(e) => setFilter({ ...filter, doctor: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            setUpdate(!update);
                                        }
                                    }}
                                />
                                {/*<Boton de buscar*/}
                                <Box sx={{ width: "20%", marginLeft: 1.5 }}>
                                    <Button
                                        onClick={() => {
                                            setUpdate(!update);
                                        }}
                                        variant="contained"
                                    >
                                        Buscar
                                    </Button>
                                </Box>
                            </Box>
                            <PatientAccordion
                                headers={headers}
                                patients={patients}
                                pagination={pagination}
                                rowsPerPageOptions={rowsPerPageOptions}
                                onRowClick={handlePatientClick}
                                onPageChange={handlePageChange}
                                type={53}
                                amounts={amounts}
                            />
                        </>
                    )}
                </>
            }
        />
    );
};
