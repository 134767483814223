import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  TextField,
  Button,
} from "@mui/material";
import DynamicTableReport from "../../components/tables/DynamicTableReport";
import Home from "../Home";
import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../functions/useWindowDimensions";
import { getPorTabularApi } from "../../api/reportes/porTabularApi";
import { getRetabulacionApi } from "../../api/reportes/retabulacionApi";

export const Retabulacion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    patient: "",
    doctor: "",
    insurance: "",
    hospital: "",
  });
  const [update, setUpdate] = useState(false);
  const { height, width } = useWindowDimensions();
  const rowsPerPageOptions = [20, 50, 100];
  const [patients, setPatients] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1, // Inicia en la página 1
    first_page_url: "",
    last_page: "",
    last_page_url: "",
    next_page_url: "",
    path: "",
    per_page: rowsPerPageOptions[0], // Utiliza el primer valor en tus opciones
    prev_page_url: "",
    to: "",
    total: "",
  });
  const headers = [
    "id",
    "#",
    "Médico",
    "Paciente",
    "Consecutivo",
    "Fecha de registro",
    "Fecha de atención",
    "Aseguradora",
    "Hospital",
    "Procedimientos Realizados",
  ];
  const n = 20;
  const m = 3;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const cellStyle = {
    padding: "10px",
    backgroundColor: "transparent",
    position: "relative",
  };

  const skeletonStyle = {
    backgroundColor: "#f5f5f5",
    animationDuration: "2s",
  };

  const extractPatientInfo = (patient) => {
    const createdAt = new Date(patient.created_at);
    const day = String(createdAt.getDate()).padStart(2, "0");
    const month = String(createdAt.getMonth() + 1).padStart(2, "0");
    const year = createdAt.getFullYear();
    const formattedCreatedAt = `${day}/${month}/${year}`;

    return [
      patient.id,
      patient.doctor_name,
      patient.patient,
      patient.order,
      formattedCreatedAt,
      patient.date_attention,
      patient.aseguradora_name,
      patient.hospital_name,
      patient.doctor_observation,
    ];
  };

  const fetchData = async (page, rowsPerPage) => {
    setIsLoading(true);
    let paginatedData = await getRetabulacionApi(filter, page, rowsPerPage);
    paginatedData = JSON.parse(paginatedData);
    paginatedData = paginatedData.patients;
    console.log("paginatedData1", paginatedData);
    let {
      data,
      current_page,
      first_page_url,
      last_page,
      last_page_url,
      next_page_url,
      path,
      per_page,
      prev_page_url,
      to,
      total,
    } = paginatedData;

    setPagination({
      current_page,
      first_page_url,
      last_page,
      last_page_url,
      next_page_url,
      path,
      per_page,
      prev_page_url,
      to,
      total,
    });
    console.log("paginatedData2", data);
    if (data) {
      console.log("data", data);
      const patientInfo = data.map(extractPatientInfo);
      console.log("patientInfo", patientInfo);
      setPatients(patientInfo);
      console.log("patients", patients);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage, update]);

  const handlePatientClick = (patientsInfo) => {
    console.log("patientsInfo", patientsInfo);
  };

  const handleRowsPerPageChange = (event) => {
    console.log("rowsPerPage", event.target.value);
    setRowsPerPage(event.target.value);
  };

  const handlePageChange = (event, page, rowsPerPage) => {
    console.log("page", page);
    setPage(page);
    setRowsPerPage(rowsPerPage);
  };

  return (
    <Home
      title="Reporte por retabulación"
      children={
        <>
          {isLoading ? (
            <TableContainer>
              <Table>
                <TableBody>
                  {[...Array(n)].map((_, i) => (
                    <TableRow key={i}>
                      {[...Array(m)].map((_, j) => (
                        <TableCell key={j} sx={cellStyle}>
                          <Skeleton
                            variant="rectangular"
                            width={200}
                            height={30}
                            sx={skeletonStyle}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 2,
                  flexDirection: "row",
                  display: "flex",
                  padding: 3,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Buscar paciente"
                  value={filter.patient}
                  onChange={(e) =>
                    setFilter({ ...filter, patient: e.target.value })
                  }
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Buscar médico"
                  value={filter.doctor}
                  onChange={(e) =>
                    setFilter({ ...filter, doctor: e.target.value })
                  }
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Buscar hospital"
                  value={filter.hospital}
                  onChange={(e) =>
                    setFilter({ ...filter, hospital: e.target.value })
                  }
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Buscar aseguradora"
                  value={filter.insurance}
                  onChange={(e) =>
                    setFilter({ ...filter, insurance: e.target.value })
                  }
                />
                {/*<Boton de buscar*/}
                <Box sx={{ width: "20%", marginLeft: 1.5 }}>
                  <Button
                    onClick={() => {
                      setUpdate(!update);
                    }}
                    variant="contained"
                  >
                    Buscar
                  </Button>
                </Box>
              </Box>
              <DynamicTableReport
                headers={headers}
                rows={patients}
                pagination={pagination}
                rowsPerPageOptions={rowsPerPageOptions}
                rowsPerPage={rowsPerPage}
                onRowClick={handlePatientClick}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
              />
            </>
          )}
        </>
      }
    />
  );
};
