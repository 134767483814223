import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Cancel, CheckCircle, PictureAsPdf } from "@material-ui/icons";
import formatMoney from "../../functions/formatMoney";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import configApp from "../../config";
import not from "../../assets/images/not.png";
import yes from "../../assets/images/yes.png";

function TableCortePagado({ patients }) {
  const [expanded, setExpanded] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actualBatch, setActualBatch] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const exportToPDF = (batch) => {
    const doc = new jsPDF("landscape", "pt", "a4");

    const selectedRows = batch.patients;

    const headers = [
      "Paciente",
      "Recibo",
      "Fecha de atención",
      "Aseguradora",
      "REP",
      "Fecha de Pago",
      "Subtotal",
      "ISR",
      "Total",
      "Comisión",
    ];

    const rowData = selectedRows.map((patient, rowIndex) => [
      patient.patient,
      patient.invoice,
      patient.fecha_atencion,
      patient.aseguradora_name,
      patient.rep,
      patient.fecha_pago,
      formatMoney(patient.subtotal),
      formatMoney(patient.isr),
      formatMoney(patient.total),
      formatMoney(patient.commission),
    ]);

    // Generar las filas de los totales
    const totalRow = Array(headers.length).fill("");
    totalRow[5] = "Total:";
    totalRow[6] = formatMoney(batch.subtotal);
    totalRow[7] = formatMoney(batch.isr);
    totalRow[8] = formatMoney(batch.total);
    totalRow[9] = formatMoney(batch.commission);

    const ivaRow = Array(headers.length).fill("");
    ivaRow[8] = "IVA:";
    ivaRow[9] = formatMoney(batch.iva);

    const commissionRow = Array(headers.length).fill("");
    commissionRow[8] = "Total comisión:";
    commissionRow[9] = formatMoney(batch.total_comission);

    // Agregar las filas de los totales a rowData
    rowData.push(totalRow, ivaRow, commissionRow);

    doc.autoTable({
      head: [headers],
      body: rowData,
      startY: 20,
      styles: {
        fontSize: 8,
        overflow: "linebreak",
        columnWidth: "auto",
        cellPadding: 4,
      },
      margin: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
      didDrawCell: function (data) {
        // Si la celda corresponde a la columna "REP"
        if (data.column.index === headers.indexOf("REP")) {
          const { x, y } = data.cell;  // Obtener la posición de la celda

          //Si es la fila del encabezado, se mantiene el texto
          if (data.row.index === 0 && data.cell.text[0] === "REP") {
            console.log("data", data.row.index, data.cell.text[0]);
            return;
          }
          console.log("dataTotal", data.row);
          console.log("dataLength", data.row.length);
          if (selectedRows.length - 1 < data.row.index) {
            return;
          }

          console.log("dataText", data.cell.text[0].length, data.cell.text[0]);
          // Decidir qué imagen dibujar basado en el contenido de la celda
          const image = data.cell.text[0].length > 0 ? yes : not;
          data.cell.text = [];  // Establece el texto de la celda a un arreglo vacío

          // Dibujar la imagen en la celda
          doc.addImage(image, 'PNG', x, y, 15, 15);
        }
        // Personalizar el estilo de las celdas de amounts
        else if (data.row.index >= selectedRows.length && data.column.index >= 8) {
          data.cell.styles.fontSize = 12; // Tamaño de letra más grande
          data.cell.styles.fontStyle = 'bold'; // Estilo negrita
        }
      }
    });

    // Agregar el texto de la cuenta al final del documento
    doc.setFontSize(18);
    doc.text("AFIRME Cuenta.- 011081002292  Clabe.- 062580110810022927 Florido Villarreal y asociados", 20, doc.internal.pageSize.height - 30);


    doc.save(`Dr.${batch.business_name} #${batch.consecutive_cuts}.pdf`);
  };

  const exportToExcel = (batch) => {
    const headers = [
      "Paciente",
      "Recibo",
      "Fecha de atención",
      "Aseguradora",
      "REP",
      "Fecha de Pago",
      "Subtotal",
      "ISR",
      "Total",
      "Comisión",
    ];

    const data = [
      headers,
      ...batch.patients.map((patient, rowIndex) => [
        patient.patient,
        patient.invoice,
        patient.fecha_atencion,
        patient.aseguradora_name,
        patient.rep ? "Sí" : "No",
        patient.fecha_pago,
        patient.subtotal.toString(),
        patient.isr.toString(),
        patient.total.toString(),
        patient.commission.toString(),
      ]),
    ];

    const sheetName = `Corte ${batch.consecutive_cuts}`;
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    const wscols = headers.map((_, i) => {
      return {
        wch: Math.max(...data.map((row) => (row[i]?.toString() || "").length)),
      };
    });
    worksheet["!cols"] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, `corte_${batch.consecutive_cuts}.xlsx`);
  };

  const sendCortePagado = (batch) => {
    console.log("batch", batch);
    setOpenModal(true);
    setActualBatch(batch);
  };

  /*   const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    }

    const handleConfirm = async () => {
        setOpenModal(false);

        console.log('batch', actualBatch);
        const selectedRows = actualBatch.patients;
        const data = selectedRows.map((patient) => patient.id);
        console.log('data', data);
        let response = await changeToCortePagadoApi(data, selectedDate);
        response = JSON.parse(response);
        if (!response.error) {
            alert('Pacientes cambiados a corte pagado');
            window.location.reload();
        } else {
            alert('Error al cambiar a corte pagado, intente de nuevo');
        }

    }*/

  /* const sendCortePagado = async (batch) => {
         console.log('batch', batch);
         const selectedRows = batch.patients;
         const data = selectedRows.map((patient) => patient.id);
         console.log('data', data);
         let response = await changeToCortePagadoApi(data);
         response = JSON.parse(response);
         if (!response.error) {
             alert('Pacientes cambiados a corte pagado');
             window.location.reload();
         } else {
             alert('Error al cambiar a corte pagado, intente de nuevo');
         }
     };*/

  const sendEmail = async (batch) => {
    console.log("batch", batch);
  };

  return (
    <div>
      {patients.map((batch) => (
        <Accordion
          key={batch.batch_id}
          expanded={expanded === batch.batch_id}
          onChange={handleChange(batch.batch_id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography>{`Corte #${batch.consecutive_cuts}, Doctor: ${batch.business_name} Fecha de corte al ${batch.date}`}</Typography>
              </Box>
              <Box style={{ display: "flex" }}>
                {/* <Button
                  variant="contained"
                  onClick={() => sendEmail(batch)}
                  style={{
                    marginRight: "10px",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <EmailIcon />
                </Button> */}
                <Button
                  variant="contained"
                  onClick={() => exportToPDF(batch)}
                  style={{
                    marginRight: "10px",
                    backgroundColor: "red",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <PictureAsPdf />
                </Button>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: configApp.styles.principalColor,

                }}
              >
                <TableRow>
                  <TableCell sx={{ color: "black" }}>Recibo</TableCell>
                  <TableCell sx={{ color: "black" }}>Paciente</TableCell>
                  <TableCell sx={{ color: "black" }}>
                    Fecha de atención
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>Aseguradora</TableCell>
                  <TableCell sx={{ color: "black" }}>REP</TableCell>
                  <TableCell sx={{ color: "black" }}>Fecha de Pago</TableCell>
                  <TableCell sx={{ color: "black" }}>Subtotal</TableCell>
                  <TableCell sx={{ color: "black" }}>ISR</TableCell>
                  <TableCell sx={{ color: "black" }}>Total</TableCell>
                  <TableCell sx={{ color: "black" }}>Comisión</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {batch.patients.map((patient) => (
                  <TableRow key={patient.id}>
                    <TableCell>{patient.patient}</TableCell>
                    <TableCell>{patient.invoice}</TableCell>
                    <TableCell>{patient.fecha_atencion}</TableCell>
                    <TableCell>{patient.aseguradora_name}</TableCell>
                    <TableCell>
                      {patient.rep ? (
                        <CheckCircle style={{ color: "green" }} />
                      ) : (
                        <Cancel style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell>{patient.fecha_pago}</TableCell>
                    <TableCell>{formatMoney(patient.subtotal)}</TableCell>
                    <TableCell>{formatMoney(patient.isr)}</TableCell>
                    <TableCell>{formatMoney(patient.total)}</TableCell>
                    <TableCell>{formatMoney(patient.commission)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={5}></TableCell>
                  <TableCell>Total: </TableCell>
                  <TableCell> {formatMoney(batch.subtotal)}</TableCell>
                  <TableCell> {formatMoney(batch.isr)}</TableCell>
                  <TableCell> {formatMoney(batch.total)}</TableCell>
                  <TableCell> {formatMoney(batch.commission)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={8}></TableCell>
                  <TableCell>IVA: </TableCell>
                  <TableCell> {formatMoney(batch.iva)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={8}></TableCell>
                  <TableCell>Total comisión: </TableCell>
                  <TableCell> {formatMoney(batch.total_comission)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default TableCortePagado;
