import config from "../../config";

export const userApi = async (data) => {
    const data1 = JSON.parse(data);
    const myHeaders = new Headers({"Content-Type": "application/json", "Authorization": `Bearer ${data1}`});
    try {
        const requestOptions = {method: "GET", headers: myHeaders, redirect: "follow",};
        const response = await fetch(`${config.config.serverUrl}api/auth/user`, requestOptions);
        const result = await response.json();
        if (!result.error) {
            return JSON.stringify(result);
        }
    } catch (error) {
        console.log("error", error);
    }
};
