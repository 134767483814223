import config from "../../config";

export const updatePatientApi = async (data) => {
    console.log('updatePatientApi', data);
    const token = localStorage.getItem('token');

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    let raw = JSON.stringify({
        id: data.id,
        patient: data.patient.toUpperCase(),
        receipt: data.receipt,
        date_attention: data.date_attention,
        tracking: data.tracking,
        doctor_observation: data.doctor_observation,
        subtotal: data.subtotal,
        isr: data.isr,
        total: data.total,
        commission: data.commission,
        observation: data.observation,
        sinister: data.sinister,
        authorization1: data.authorization1,
        folio: data.folio,
        portal_date: data.portal_date,
        doctor_id: data.doctor_id,
        insurance_id: data.insurance_id,
        hospital_id: data.hospital_id,
        payment_date: data.payment_date,
        payment_type: data.payment_type,
        payment_observation: data.payment_observation,
        imp_local: data.imp_local,
        invoice: data.invoice,
        tabulation: data.tabulation,
        xml: data.xml,
        retabultation_obs: data.retabultation_obs,
        status_id: data.status_id,
        payment_cutoff_date: data.payment_cutoff_date,
        cancel_obs: data.cancel_obs,
        authorization: data.authorization,
        payment_pdf: data.payment_pdf,
        payment_xml: data.payment_xml,
        payment_due_date: data.payment_due_date,
        comments: data.comments,
    });

    let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response;
    response = await fetch(`${config.config.serverUrl}api/updatePatient/${data.id}`, requestOptions)
    return response.json();

}
