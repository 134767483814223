import config from '../../config';

export const doctorsApi = async () => {
    const token = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            Cookie: `XSRF-TOKEN=${token}`,
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
    };

    const response = await fetch(`${config.config.serverUrl}api/doctors`, requestOptions);
    return response.json();
};
